import React, {useState, useEffect} from 'react'
import {apiBaseUrl, normalApiTimeout} from '../Config'
import axios from 'axios'
import {Auth} from 'aws-amplify'

const OperatorsCacheContext = React.createContext([[], () => {}])

const OperatorsCacheProvider = (props) => {
  const [state, setState] = useState([])

  useEffect(() => {
    async function retrieveOperators() {
      const session = await Auth.currentSession()
      const token = session.getAccessToken().getJwtToken()
      if (token == null) {
        console.error('operators cache, not authenticated')
        return
      }
      const result = await axios({
        url: `${apiBaseUrl}/users?state=false`,
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`},
        timeout: normalApiTimeout,
      })
      // console.log('Operators cache', result ? result.data : result)
      setState(result ? result.data : [])
    }
    retrieveOperators()
  }, [])

  return (
    <OperatorsCacheContext.Provider value={[state, setState]}>
      {props.children}
    </OperatorsCacheContext.Provider>
  )
}

export {OperatorsCacheContext, OperatorsCacheProvider}
