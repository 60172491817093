import {useState} from 'react'

export default function useInput(initialValue) {
  const [value, setValue] = useState(initialValue)

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value)
      },
    },
  }
}

export function useArrayInput(initialValue = []) {
  const [value, setValue] = useState(initialValue)
  return {
    value,
    setValue,
    reset: () => setValue([]),
    bind: {
      value,
      onChange: (event) => {
        const {value} = event.target
        const values =
          value == null
            ? []
            : typeof value === 'string'
            ? value.split(',')
            : [value]
        setValue(values)
      },
    },
  }
}
