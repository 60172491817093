import {isAfter} from 'date-fns'
import {getDistanceFromLatLonInKm} from './geo-utils'
import {movingStatusMinutesLimit, movingStatusKmLimit} from '../Config'
import {TrackingStates} from './tracking-utils'

export const MovingStates = Object.freeze({
  MOVING: 'moving',
  STOPPED: 'stopped',
  INVALID_POSITION: 'invalid_position',
  UNREACHABLE: 'unreachable',
})

export const getMovingStatus = (lastOrderedPositions, trackingStatus) => {
  switch (trackingStatus) {
    case TrackingStates.NOT_TRACKING:
      return MovingStates.STOPPED
    case TrackingStates.UNREACHABLE:
      return MovingStates.UNREACHABLE
    case TrackingStates.INVALID_POSITION:
      return MovingStates.INVALID_POSITION
    default:
      return computeStatus(lastOrderedPositions)
  }
}

function computeTotalDistance(validPositions) {
  const {total} = validPositions.reduce(
    (accumulator, {latitude, longitude}) => {
      const {prevLat, prevLng, total = 0} = accumulator
      if (prevLat == null || prevLng === null) {
        return {
          total: 0,
          prevLat: latitude,
          prevLng: longitude,
        }
      }
      const currDistance = getDistanceFromLatLonInKm(
        prevLat,
        prevLng,
        latitude,
        longitude
      )
      return {
        total: total + currDistance,
        prevLat: latitude,
        prevLng: longitude,
      }
    }
  )
  return total
}

function computeStatus(lastOrderedPositions = []) {
  // remove all too old positions
  const validPositions = lastOrderedPositions.filter(({serverTimestamp}) =>
    isAfter(new Date(serverTimestamp), movingStatusMinutesLimit)
  )

  // to be considered moving we must have at least 2 positions to estimate distance between positions
  if (validPositions.length < 2) {
    return MovingStates.STOPPED
  }

  // calculate all the traveled distance point-to-point in the valid timespan
  const totalDistance = computeTotalDistance(validPositions)

  // operator is moving only if traveled distance is greater than limit
  return totalDistance < movingStatusKmLimit
    ? MovingStates.STOPPED
    : MovingStates.MOVING
}
