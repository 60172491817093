import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {Card, Image} from 'react-bootstrap'
import ImagePreview from '../ImagePreview'

AndroidPreview.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
}

// ANDROID NOTIFICA TEXT
// font-family: Roboto-Regular;
// font-size: 14px;
// color: rgba(0,0,0,0.54);
// text-align: left;
// line-height: 20px;

const styles = {
  card: {
    width: '360px',
    borderRadius: 0,
    borderWidth: 0,
  },
  header: {
    borderBottomWidth: 0,
    backgroundColor: '#fff',
    padding: '5px 9px',
  },
  appName: {
    // fontFamily: 'Roboto-Regular',
    fontSize: '12px',
    color: '#05386B',
    textAlign: 'left',
    lineHeight: '16px',
  },
  body: {
    minHeight: '60px',
  },
  title: {
    // fontFamily: 'Roboto-Regular',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'left',
    lineHeight: '20px',
  },
  text: {
    // fontFamily: 'Roboto-Regular',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.54)',
    textAlign: 'left',
    lineHeight: '20px',
  },
  logo: {},
}

function AndroidPreview({text, title = '', imageUrl}) {
  return (
    <Card style={styles.card}>
      <Card.Header className="d-flex align-items-center" style={styles.header}>
        {/* <Image
          src="../imgs/pushdeck-logo-solo.svg"
          alt="App logo"
          className="mr-1"
          style={styles.logo}
        /> */}
        <span style={styles.appName}>GbPeople</span>
      </Card.Header>
      <Card.Body style={styles.body} className="pt-1">
        <ImagePreview imageUrl={imageUrl} side="right" size={60} />
        <Card.Text style={styles.title} className="mb-1 mt-1">
          {title}
        </Card.Text>
        <Card.Text style={styles.text} className="mt-0">
          {text}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default memo(AndroidPreview)
