import React from 'react'
// import {useHistory} from 'react-router-dom'
import CampaignsTable from './CampaignsTable'
// import rawMocks from '../../Mocks/campaignsMock.json'

// const mockData = rawMocks.map(mock => ({
//   ...mock,
//   startDate: new Date(mock.startDate),
// }))
function CampaignsTableWrapper(props) {
  // const history = useHistory()
  const onRowClick = (campaignId) => {
    // history.push(`/campaigns/${campaignId}`)
    console.log(`Selected campaign ${campaignId}`)
  }
  // const campaigns = props.campaigns || mockData
  return (
    <CampaignsTable
      campaigns={props.campaigns}
      onRowClick={onRowClick}
      enableProgressive={props.enableProgressive}
      hideTopics={props.hideTopics}
      topics={props.topics}
    />
  )
}

export default CampaignsTableWrapper
