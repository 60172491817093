import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import logo from '../../assets/images/logo_bucciarelli_ext.svg'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    // // height: '100vh',
    // flexGrow: 1,
    // flexDirection: 'column',
    // paddingLeft: theme.spacing(10),
    // backgroundColor: '#F1F1F1',
  },
  logo: {
    // height: '20vh',
    width: '250px',
    marginTop: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(10),
  },
  form: {
    marginTop: theme.spacing(1),
    maxWidth: theme.spacing(50),
  },
}))

function AuthContainer({children, title, subtitle}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <header>
        <img src={logo} className={classes.logo} alt="logo" />
      </header>
      <main className={classes.main}>
        {/* <Typography variant="h4">{title}</Typography>
        {subtitle && <Typography variant="h6">{subtitle}</Typography>}
        <div className={classes.form}>{children}</div> */}
      </main>
    </div>
  )
}

AuthContainer.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default AuthContainer
