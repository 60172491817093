import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    color: 'red',
  },
}))

function LabeledCell({label, description, descriptionError}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant="subtitle2">
        <b>{label}</b>
      </Typography>
      <Typography variant="body2">{description}</Typography>
      {!!descriptionError && (
        <Typography variant="body2" className={classes.errorText}>
          {descriptionError}
        </Typography>
      )}
    </div>
  )
}

LabeledCell.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  descriptionError: PropTypes.string,
}

export default LabeledCell
