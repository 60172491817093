import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    maxWidth: theme.spacing(15),
    display: 'block',
  },
  form: {},
}))

function ResetPasswordForm({onReset, email, disabled}) {
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const checkEqualPassword = () =>
    !confirmPassword || confirmPassword === password

  const isFormValid =
    !disabled && !!email && password === confirmPassword && password.length >= 8

  const onSubmit = (e) => {
    e.preventDefault()
    if (isFormValid) {
      onReset(password, confirmPassword)
    }
  }

  const arePasswordDifferent = !checkEqualPassword()
  const isPasswordTooShort = !!password && password.length < 8

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <TextField
        id="email"
        name="email"
        autoComplete="email"
        disabled
        required
        fullWidth
        label="Email"
        margin="normal"
        value={email}
      />
      <TextField
        id="new-password"
        name="new-password"
        required
        fullWidth
        label="Crea nuova password"
        type="password"
        error={isPasswordTooShort}
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        margin="normal"
        autoFocus
        helperText={
          isPasswordTooShort ? 'La password deve avere almeno 8 caratteri' : ''
        }
      />
      <TextField
        error={arePasswordDifferent}
        id="confirm-password"
        name="confirm-password"
        required
        fullWidth
        type="password"
        label="Ripeti nuova password"
        margin="normal"
        onChange={(e) => setConfirmPassword(e.target.value)}
        value={confirmPassword}
        helperText={arePasswordDifferent ? 'Le password non corrispondono' : ''}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}>
        Salva
      </Button>
    </form>
  )
}

export default ResetPasswordForm
