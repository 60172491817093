import {useContext, useCallback} from 'react'
import {CampaignsContext} from '../Contexts/CampaignsContext'
import * as api from '../Services/api'

export default function useCampaigns() {
  const [, setState] = useContext(CampaignsContext)

  const resetFetchRequest = useCallback(
    (label) => {
      setState((state) => ({
        ...state,
        [label]: {
          fetching: false,
          error: null,
        },
      }))
    },
    [setState]
  )

  const resetPostRequest = useCallback(() => {
    setState((state) => ({
      ...state,
      postRequest: {
        sending: false,
        error: null,
        success: false,
        id: null,
      },
    }))
  }, [setState])

  const getSingleCampaign = useCallback(
    async (id, isUpdate = false) => {
      setState((state) => ({
        ...state,
        selectedCampaign: isUpdate ? state.selectedCampaign : null,
        singleRequest: {
          fetching: true,
          error: null,
        },
      }))
      try {
        const response = await api.getCampaign(id)
        const {data} = response
        setState((state) => ({
          ...state,
          selectedCampaign: data || null,
          singleRequest: {
            fetching: false,
            error: null,
          },
        }))
      } catch (error) {
        setState((state) => ({
          ...state,
          singleRequest: {
            fetching: false,
            error: error,
          },
        }))
      }
    },
    [setState]
  )

  const getCampaigns = useCallback(async () => {
    setState((state) => ({
      ...state,
      listRequest: {
        fetching: true,
        error: null,
      },
    }))
    try {
      const response = await api.getCampaigns()
      const {data} = response
      setState((state) => ({
        ...state,
        campaigns: data || [],
        listRequest: {
          fetching: false,
          error: null,
        },
      }))
    } catch (error) {
      setState((state) => ({
        ...state,
        listRequest: {
          fetching: false,
          error: error,
        },
      }))
    }
  }, [setState])

  const createCampaign = useCallback(
    async (values) => {
      setState((state) => ({
        ...state,
        postRequest: {
          sending: true,
          error: null,
          success: false,
          id: null,
        },
      }))
      try {
        const response = await api.postCampaign(values)
        const {data = {}} = response
        setState((state) => ({
          ...state,
          postRequest: {
            fetching: false,
            error: null,
            success: true,
            id: data.id,
          },
        }))
      } catch (error) {
        setState((state) => ({
          ...state,
          postRequest: {
            fetching: false,
            error,
          },
        }))
      }
    },
    [setState]
  )

  return {
    resetFetchRequest,
    resetPostRequest,
    getCampaigns,
    getSingleCampaign,
    createCampaign,
  }
}
