import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {TopicsColors} from './index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/free-solid-svg-icons'

TopicBadge.propTypes = {
  id: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  forcedName: PropTypes.string,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      colorIndex: PropTypes.number.isRequired,
    })
  ),
}

function TopicBadge(props) {
  const {id = '', topics = [], iconSize = 14, forcedName} = props
  if (!topics || topics.length === 0) {
    return null
  }
  // const topicIds = topics.map(({id}) => id)
  // const index = topicIds.indexOf(id)
  const topic = topics.find((t) => t.id === id)
  if (!topic) {
    return null
  }
  const {name, colorIndex, service} = topic
  const color = service
    ? 'darkGrey'
    : TopicsColors[colorIndex % TopicsColors.length]
  return (
    <span>
      <FontAwesomeIcon
        icon={faCircle}
        color={color}
        style={{fontSize: iconSize}}
        className="mr-1"
      />
      {forcedName || name}
    </span>
  )
}

export default memo(TopicBadge)
