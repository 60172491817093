import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DetailIcon from '@material-ui/icons/ArrowForward'
import {getRawBallStyles, useBallStyles} from '../Ball'
import OperatorStatus from '../OperatorStatus'
import MapPopupTable from './MapPopupTable'

const useStyles = makeStyles((theme) => ({
  header: {
    // backgroundColor: 'pink',
    borderBottom: '1px solid lightGrey',
  },
  card: {
    minWidth: theme.spacing(40),
  },
  avatar: {
    width: '30px',
    height: '30px',
    // backgroundColor: 'red',
  },
  contentContainer: {
    flex: 1,
    // backgroundColor: 'lightGrey',
  },
  paddedContent: {
    marginLeft: theme.spacing(4),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  verticalSpacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
}))

function MapPopup(props) {
  const classes = useStyles()
  const ballClasses = useBallStyles()
  const ballStyles = getRawBallStyles()

  const {
    onDetail,
    name = '-',
    surname = '',
    color = 'grey',
    lastPositions = [],
    lastState,
  } = props
  const colorStyle = `${color}Bg`
  const colorDefault = {
    backgroundColor: ballStyles[colorStyle].backgroundColor,
  }

  return (
    <Card className={classes.card} elevation={0}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="Color"
            sizes="small"
            className={clsx(ballClasses[colorStyle], classes.avatar)}
            style={colorDefault}
          />
        }
        action={
          <IconButton aria-label="To detail" onClick={onDetail}>
            <DetailIcon />
          </IconButton>
        }
        title={<Typography variant="h5">{`${name} ${surname}`}</Typography>}
        className={classes.header}
        // subheader="September 14, 2016"
      />
      <CardContent>
        <div className={classes.contentContainer}>
          <div className={classes.verticalSpacing}>
            <OperatorStatus
              lastPositions={lastPositions}
              lastState={lastState}
              fullSize
            />
          </div>

          <Typography variant="subtitle2" className={classes.title}>
            <b>Ultima rilevazione</b>
          </Typography>
        </div>
        <MapPopupTable lastPositions={lastPositions.slice(0, 1)} />
      </CardContent>
    </Card>
  )
}

MapPopup.propTypes = {
  onDetail: PropTypes.func.isRequired,
  name: PropTypes.string,
  surname: PropTypes.string,
  color: PropTypes.string,
  lastPositions: PropTypes.array,
  lastState: PropTypes.object,
}

export default MapPopup
