import React, {useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OperatorDetailInfo from './OperatorDetailInfo'
import OperatorDetailApp from './OperatorDetailApp'
import OperatorStatus from '../OperatorStatus'
import LastPositions from './OperatorDetailLastPositions'
import Checkins from './OperatorDetailCheckins'
import LastStates from './OperatorDetailStates'
import BackButton from '../BackButton'
import {scrollToTop} from '../../utilities/layout-utils'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  container: {
    marginLeft: theme.spacing(4),
    maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  verticalSpacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
}))

function OperatorDetail(props) {
  const classes = useStyles()
  const {
    lastPositions = [],
    checkins = [],
    lastStates = [],
    userData,
    loadingUser,
    onBack,
    onEdit,
    fetching,
    onResend,
  } = props
  const lastState = lastStates.length > 0 ? lastStates[0] : {}

  useLayoutEffect(scrollToTop, [])

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, classes.verticalSpacing)}>
        <BackButton onBack={onBack} />
      </div>
      <div className={classes.container}>
        <OperatorDetailInfo
          {...userData}
          loadingUser={loadingUser}
          onEdit={onEdit}
          onResend={onResend}
          fetching={fetching}
        />
      </div>
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          App Mobile
        </Typography>
        <OperatorDetailApp {...userData} />
      </div>
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Informazioni
        </Typography>
        <OperatorStatus lastPositions={lastPositions} lastState={lastState} />
      </div>
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Ultime posizioni rilevate
        </Typography>
      </div>
      <LastPositions lastPositions={lastPositions} />
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Numero di check-in rilevati: {checkins.length}
        </Typography>
      </div>
      <Checkins checkins={checkins} />
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Ultimi start/stop
        </Typography>
      </div>
      <LastStates lastStates={lastStates} />
    </div>
  )
}

OperatorDetail.propTypes = {
  userData: PropTypes.object,
  loadingUser: PropTypes.bool,
  errorUser: PropTypes.object,
  lastPositions: PropTypes.array,
  // loadingLastPositions: PropTypes.bool,
  errorLastPositions: PropTypes.object,
  checkins: PropTypes.array,
  // loadingCheckins: PropTypes.bool,
  errorCheckins: PropTypes.object,
  lastStates: PropTypes.array,
  // loadingLastStates: PropTypes.bool,
  errorLastStates: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default OperatorDetail
