/* eslint-disable no-template-curly-in-string */
import {string, number, object, ref, bool} from 'yup'
import {Colors, UserSpecializations} from '../../Config'

const schema = object().shape({
  name: string().required('Il nome è obbligatorio'),
  surname: string().required('Il cognome è obbligatorio'),
  email: string()
    .required("L'email è obbligatoria")
    .email("L'email non è valida"),
  color: string()
    .required('Il colore è obbligatorio')
    .oneOf(
      Object.values(Colors),
      'Il colore non è valido, deve essere uno dei seguenti: ${values}'
    ),
  startHour: number()
    .required('Obbligatorio')
    .integer('Deve essere un valore intero')
    .min(0, 'Il valore minimo è ${min}')
    .max(23, 'Il valore massimo è ${max}'),
  endHour: number()
    .required('Obbligatorio')
    .integer('Deve essere un valore intero')
    .moreThan(
      ref('startHour'),
      "L'orario di fine deve essere maggiore di quello di inizio (${more})"
    )
    // .min(0, 'Il valore minimo è ${min}')
    .max(23, 'Il valore massimo è ${max}'),
  startMinute: number()
    .required('Obbligatorio')
    .integer('Deve essere un valore intero')
    .min(0, 'Il valore minimo è ${min}')
    .max(59, 'Il valore massimo è ${max}'),
  endMinute: number()
    .required('Obbligatorio')
    .integer('Deve essere un valore intero')
    .min(0, 'Il valore minimo è ${min}')
    .max(59, 'Il valore massimo è ${max}'),
  specialization: string()
    .notRequired()
    .nullable()
    .oneOf(
      [null, ...UserSpecializations],
      'La specializzazione non è valida, deve essere una delle seguenti: ${values}'
    ),
  isGbPeopleExposable: bool().notRequired().nullable(),
  phoneNumber: string().when('isGbPeopleExposable', {
    is: true, // alternatively: (val) => val == true
    then: string()
      .required('Obbligatorio per operatori visibili in GbPeople')
      .trim('Inserire un numero di telefono valido')
      .min(1, 'Inserire un numero di telefono valido')
      .matches(
        /^(\+|\s|-|\d|\(|\))*$/,
        'Inserire un numero di telefono valido'
      ),
    otherwise: string()
      .notRequired()
      .nullable()
      .trim('Inserire un numero di telefono valido')
      .min(1, 'Inserire un numero di telefono valido')
      .matches(
        /^(\+|\s|-|\d|\(|\))*$/,
        'Inserire un numero di telefono valido'
      ),
  }),
})
export default schema
