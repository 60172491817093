import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Ball from '../Ball'
import LabeledCell from '../DetailLabeledCell'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
  userClassToString,
  workHourToString,
} from '../../utilities/user-transformers'
import {UserClass} from '../../Config'

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft: theme.spacing(4),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  gridContainerTitle: {
    // backgroundColor: 'green',
    borderBottom: '2px solid #C4CDD5',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  gridContainer: {
    // backgroundColor: 'green',
  },
  gridItem: {
    // backgroundColor: 'red',
    marginBottom: theme.spacing(2),
  },
  ball: {
    marginRight: theme.spacing(1),
  },
  verticalStack: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

function DetailInfo(props) {
  const classes = useStyles()
  const {
    name = '-',
    surname = '-',
    class: userClass,
    color = 'grey',
    email = '-',
    disabled,
    workHour = '-',
    fetching,
    onResend,
    onEdit,
    isVerified = true,
    isGbPeopleExposable = false,
    specialization = '-',
    phoneNumber = '-',
  } = props
  // console.log({props})
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainerTitle}>
        <Grid item xs={5} className={classes.gridItemTitle}>
          <Typography variant="h5">
            <Ball color={disabled ? 'grey' : color} className={classes.ball} />
            {userClassToString(userClass)}
            {disabled
              ? ' - DISABILITATO'
              : !isVerified
              ? ' - NON VERIFICATO'
              : ''}
          </Typography>
        </Grid>
        <Grid item xs={isVerified ? 5 : 3} className={classes.gridItemTitle}>
          {' '}
        </Grid>
        {!isVerified && (
          <Grid item xs={2} className={classes.buttonContainer}>
            <Button
              color="primary"
              className={classes.button}
              size="small"
              disabled={fetching}
              onClick={onResend}
              variant="contained">
              Rimanda invito
            </Button>
          </Grid>
        )}
        {userClass !== UserClass.OPERATOR && (
          <Grid item xs={2} className={classes.buttonContainer}>
            <Button
              color="primary"
              className={classes.button}
              size="small"
              disabled={fetching}
              onClick={onEdit}
              variant="contained">
              Modifica
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container className={classes.gridContainer}>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Nome" description={name} />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Cognome" description={surname} />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Email" description={email} />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Numero di telefono" description={phoneNumber} />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Specializzazione" description={specialization} />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Orario di lavoro"
            description={workHourToString(workHour)}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Visibile in GbPeople"
            description={isGbPeopleExposable ? 'Sì' : 'No'}
          />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          {' '}
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>
      </Grid>
    </div>
  )
}

DetailInfo.propTypes = {
  onResend: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  name: PropTypes.string,
  surname: PropTypes.string,
  class: PropTypes.string,
  color: PropTypes.string,
  email: PropTypes.string,
  disabled: PropTypes.bool,
  os: PropTypes.string,
  osVersion: PropTypes.string,
  version: PropTypes.string,
  phoneNumber: PropTypes.string,
  specialization: PropTypes.string,
  isGbPeopleExposable: PropTypes.bool,
  workHour: PropTypes.oneOfType([
    PropTypes.shape({
      startHour: PropTypes.string.isRequired,
      startMinute: PropTypes.string.isRequired,
      endHour: PropTypes.string.isRequired,
      endMinute: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
}

export default DetailInfo
