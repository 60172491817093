import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import useAxios from 'axios-hooks'
import {useAuthenticator} from '@aws-amplify/ui-react'
import SaveSnackbar from '../CustomSnackbar/SaveSnackbar'
import SettingsForm from './SettingsForm'
import {Auth} from 'aws-amplify'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
    // marginTop: '3px',
    // backgroundColor: 'red',
    // position: 'relative',
  },
}))

function Settings() {
  const classes = useStyles()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [saving, setSaving] = useState(false)
  const [errorSaving, setErrorSaving] = useState()
  const [saveResponseData, setSaveResponseData] = useState()

  // const [
  //   {data: saveResponseData, loading: saving, error: errorSaving},
  //   executeSave,
  // ] = useAxios(
  //   {
  //     url: `${apiBaseUrl}/password`,
  //     method: 'PUT',
  //     headers: {Authorization: `Bearer ${token}`},
  //     timeout: normalApiTimeout,
  //   },
  //   {manual: true}
  // )

  // const onSave = async () =>
  //   executeSave({data: {oldPassword, newPassword: password}})

  const onSave = async () => {
    try {
      setSaveResponseData(null)
      setErrorSaving(null)
      setSaving(true)
      const response = await Auth.changePassword(user, oldPassword, password)
      setSaveResponseData(response)
      setSaving(false)
    } catch (error) {
      console.error(error)
      setErrorSaving(error)
      setSaving(false)
    }
  }

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={false} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <SettingsForm
          onSave={onSave}
          saving={saving}
          errorSaving={errorSaving}
          saveResponseData={saveResponseData}
          errors={{}}
          password={password}
          oldPassword={oldPassword}
          confirmPassword={confirmPassword}
          setPassword={setPassword}
          setOldPassword={setOldPassword}
          setConfirmPassword={setConfirmPassword}
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <SaveSnackbar
        response={saveResponseData}
        error={errorSaving}
        errorMessage={
          'Errore durante il tentativo di aggiornamento password. La password non è stata modificata.'
        }
        successMessage="Password aggiornata."
      />
    </div>
  )
}

export default Settings
