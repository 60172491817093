import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LabeledCell from '../DetailLabeledCell'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft: theme.spacing(4),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  gridContainerTitle: {
    // backgroundColor: 'green',
    borderBottom: '2px solid #C4CDD5',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  gridContainer: {
    // backgroundColor: 'green',
  },
  gridItem: {
    // backgroundColor: 'red',
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

function DetailInfo(props) {
  const classes = useStyles()
  const {
    name = '-',
    address = '-',
    latitude = '-',
    longitude = '-',
    disabled = false,
    loadingCustomer,
    onEdit,
  } = props
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainerTitle}>
        <Grid item xs={10} className={classes.gridItemTitle}>
          <Typography variant="h5">
            {name}
            {disabled && <small>{' - DISABILITATO'}</small>}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Button
            color="primary"
            className={classes.button}
            size="small"
            disabled={loadingCustomer}
            onClick={onEdit}
            variant="contained">
            Modifica
          </Button>
        </Grid>
      </Grid>

      <Grid container className={classes.gridContainer}>
        <Grid item xs={10} className={classes.gridItem}>
          <LabeledCell label="Indirizzo" description={address} />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Latitudine" description={latitude} />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Longitudine" description={longitude} />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>
      </Grid>
    </div>
  )
}

DetailInfo.propTypes = {
  onEdit: PropTypes.func.isRequired,
  loadingCustomer: PropTypes.bool.isRequired,
  name: PropTypes.string,
  address: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
}

export default DetailInfo
