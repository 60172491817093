import React from 'react'
import PropTypes from 'prop-types'
import {Navigate, useLocation} from 'react-router-dom'
import {UserClass} from '../Config'

function RequireBaseAuth({children, isAuthenticated, userClass}) {
  const location = useLocation()
  if (isAuthenticated && userClass === UserClass.ADMINISTRATOR) {
    return (
      <Navigate
        to={{
          pathname: '/map',
        }}
      />
    )
  } else if (isAuthenticated) {
    return children
  } else {
    return (
      <Navigate
        to={{
          pathname: '/login',
          // state: {from: props.location},
        }}
        state={{from: location}}
      />
    )
  }
}

RequireBaseAuth.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  userClass: PropTypes.oneOf(Object.values(UserClass)).isRequired,
}

export default RequireBaseAuth
