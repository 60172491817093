import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
// import TestPage from '../Components/TestPage'
// import Login from '../Components/Login'
import ResetPassword from '../Components/ResetPassword'
import ResetPasswordRequest from '../Components/ResetPasswordRequest'
// import Register from '../Components/Register'
import Expo from '../Components/Expo'
import AdminMap from '../Components/Map'
import Settings from '../Components/Settings'
import Operators from '../Components/Operators'
import OperatorDetail from '../Components/OperatorDetail'
import OperatorEdit from '../Components/OperatorEdit'
import OperatorNew from '../Components/OperatorNew'
import Customers from '../Components/Customers'
import CustomerDetail from '../Components/CustomerDetail'
import CustomerEdit from '../Components/CustomerEdit'
import GbpCustomers from '../Components/GbpCustomers'
import GbpCustomerDetail from '../Components/GbpCustomerDetail'
import Pushdeck from '../Pushdeck'
import RequireBaseAuth from './RequireBaseAuth'
import RequireAdminAuth from './RequireAdminAuth'
import LinearProgress from '@material-ui/core/LinearProgress'
import {useAuthenticator} from '@aws-amplify/ui-react'
import {UserClass} from '../Config'

const Home = () => <Navigate to="/operators" replace />

const Routing = () => {
  const {route, user, isPending} = useAuthenticator((context) => [
    context.route,
    context.user,
    context.isPending,
  ])
  // const {user} = useAuthenticator((context) => [context.user])
  // console.log({user})
  const cognitoGroups = user?.getSignInUserSession()?.getAccessToken()
    ?.payload?.['cognito:groups']
  // const cognitoGroups =
  //   user?.signInUserSession?.accessToken?.payload?.['cognito:groups']
  // console.log('groups', cognitoGroups)
  if (isPending || cognitoGroups == null) {
    console.log('loading...')
    return <LinearProgress />
  }
  const isAuthenticated = route === 'authenticated'
  const userClass = cognitoGroups.includes('administrator')
    ? UserClass.ADMINISTRATOR
    : UserClass.EXHIBITOR
  return (
    <Router>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}

        {/* <Route path="/register" element={<Register />} /> */}

        <Route
          path="/reset-password-request"
          element={<ResetPasswordRequest />}
        />

        <Route path="/reset-password" element={<ResetPassword />} />

        <Route
          path="/expo"
          element={
            <RequireBaseAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <Expo />
            </RequireBaseAuth>
          }
        />

        <Route
          path="/map"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <AdminMap />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/operators"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <Operators />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/settings"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <Settings />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/operators/new"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <OperatorNew />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/operators/:userId"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <OperatorDetail />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/operators/:userId/edit"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <OperatorEdit />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/customers"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <Customers />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/customers/:customerHashKey/:customerId"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <CustomerDetail />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/customers/:customerHashKey/:customerId/edit"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <CustomerEdit />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/gbpeople"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <GbpCustomers />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/gbpeople/:customerId"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <GbpCustomerDetail />
            </RequireAdminAuth>
          }
        />

        <Route
          path="/pushdeck"
          element={
            <RequireAdminAuth
              isAuthenticated={isAuthenticated}
              userClass={userClass}>
              <Pushdeck />
            </RequireAdminAuth>
          }
        />

        <Route path="/*" element={<Home />} />
      </Routes>
    </Router>
  )
}

export default Routing
