import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import BackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  label: {
    textTransform: 'none',
  },
  noLeftPadding: {
    paddingLeft: 0,
  },
}))

function BackButton({onBack}) {
  const classes = useStyles()

  return (
    <Button
      variant="text"
      color="default"
      size="small"
      classes={{label: classes.label}}
      className={classes.noLeftPadding}
      onClick={onBack}>
      <BackIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
      indietro
    </Button>
  )
}

BackButton.propTypes = {
  onBack: PropTypes.func.isRequired,
}

export default BackButton
