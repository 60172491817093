import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    maxWidth: theme.spacing(15),
    display: 'block',
  },
  form: {},
}))

function ResetPasswordRequestForm({onRequest, disabled}) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const onSubmit = (e) => {
    e.preventDefault()
    onRequest(email)
  }
  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <TextField
        disabled={disabled}
        id="email"
        name="email"
        autoComplete="email"
        required
        fullWidth
        label="Email"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
      />
      <Button
        disabled={disabled || !email}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}>
        Invia
      </Button>
    </form>
  )
}

export default ResetPasswordRequestForm
