import {useContext, useCallback} from 'react'
import {TopicsContext} from '../Contexts/TopicsContext'
import * as api from '../Services/api'

export default function useTopics() {
  const [, setState] = useContext(TopicsContext)

  const fetchTopicStats = useCallback(
    async (withCampaigns = false) => {
      setState((state) => ({
        ...state,
        topicsRequest: {
          fetching: true,
          error: null,
        },
      }))
      try {
        const response = await api.getTopics(withCampaigns)
        const {data} = response
        const topicsStats = data && data.length > 0 ? data[0].Topics || [] : []
        setState((state) => ({
          ...state,
          topicsStats,
          topicsRequest: {
            fetching: false,
            error: null,
          },
        }))
      } catch (error) {
        setState((state) => ({
          ...state,
          topicsRequest: {
            fetching: false,
            error,
          },
        }))
      }
    },
    [setState]
  )

  const fetchSingleTopic = useCallback(
    async (topicId, isRefresh = false) => {
      setState((state) => ({
        ...state,
        singleTopic: isRefresh ? state.singleTopic : null,
        singleTopicRequest: {
          fetching: true,
          error: null,
        },
      }))
      try {
        const response = await api.getTopic(topicId)
        const {data} = response
        setState((state) => ({
          ...state,
          singleTopic: data,
          singleTopicRequest: {
            fetching: false,
            error: null,
          },
        }))
      } catch (error) {
        setState((state) => ({
          ...state,
          singleTopicRequest: {
            fetching: false,
            error,
          },
        }))
      }
    },
    [setState]
  )

  const editTopic = useCallback(
    async (topicId, name) => {
      setState((state) => ({
        ...state,
        editTopicRequest: {
          success: false,
          fetching: true,
          error: null,
        },
      }))
      try {
        await api.editTopic(topicId, name)
        setState((state) => ({
          ...state,
          editTopicRequest: {
            success: true,
            fetching: false,
            error: null,
          },
        }))
        await fetchSingleTopic(topicId, true)
      } catch (error) {
        setState((state) => ({
          ...state,
          editTopicRequest: {
            success: false,
            fetching: false,
            error,
          },
        }))
      }
    },
    [fetchSingleTopic, setState]
  )

  const addTopic = useCallback(
    async (name) => {
      setState((state) => ({
        ...state,
        addTopicRequest: {
          success: false,
          fetching: true,
          error: null,
        },
      }))
      try {
        await api.postNewTopic(name)
        setState((state) => ({
          ...state,
          addTopicRequest: {
            success: true,
            fetching: false,
            error: null,
          },
        }))
        await fetchTopicStats()
      } catch (error) {
        setState((state) => ({
          ...state,
          addTopicRequest: {
            success: false,
            fetching: false,
            error,
          },
        }))
      }
    },
    [fetchTopicStats, setState]
  )

  const resetRequest = useCallback(
    async (label) => {
      setState((state) => ({
        ...state,
        [label]: {
          success: false,
          fetching: false,
          error: null,
        },
      }))
    },
    [setState]
  )

  const launchUpdateTopicInfoTask = useCallback(async () => {
    await api.launchUpdateTopicInfoTask()
    setTimeout(() => {
      fetchTopicStats()
    }, 5000)
  }, [fetchTopicStats])

  return {
    fetchTopicStats,
    fetchSingleTopic,
    resetRequest,
    addTopic,
    editTopic,
    launchUpdateTopicInfoTask,
  }
}
