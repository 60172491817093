import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {Card} from 'react-bootstrap'
import format from 'date-fns/format'
import {it} from 'date-fns/locale'
import OsIcons from '../OsIcons'
import Status from '../CampaignStatus'
import TopicBadge from '../TopicBadge'
import ImagePreview from '../ImagePreview'

CampaignRecap.propType = {
  title: PropTypes.string,
  body: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  osList: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  campaignTopics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  isNewCampaign: PropTypes.bool,
  topics: PropTypes.array,
}

const styles = {
  wrap: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    flexWrap: 'wrap',
  },
}

export default function CampaignRecap(props) {
  const {
    title = '',
    body = '',
    url = '',
    imageUrl = '',
    osList = [],
    status = '',
    // topicText: topic,
    startDate,
    campaignTopics = [],
    isNewCampaign = false,
    topics,
  } = props
  const date = startDate
    ? format(startDate, "PPPP' alle 'p", {locale: it})
    : isNewCampaign
    ? 'immediata'
    : ''
  const isValidBody = !isNewCampaign || (body && body.trim())
  const bodyStyle = isValidBody ? {} : {color: '#f00'}
  return (
    <Card.Body>
      <span className="formLabel">Titolo della notifica</span>
      <p className="lead">{title || '-'}</p>
      <span className="formLabel">Testo della notifica</span>
      <p style={bodyStyle} className="lead">
        {isValidBody ? body : 'Testo della notifica obbligatorio'}
      </p>
      <div className="d-flex justify-content-between">
        <div style={{...styles.wrap, maxWidth: '85%'}}>
          <span className="formLabel">Url della notifica</span>
          <p className="lead pr-5">
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </p>
        </div>
        <div>
          <span className="formLabel">Sistema Operativo</span>
          <OsIcons osList={osList} classes="os-icon mr-3" />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div style={{...styles.wrap, maxWidth: '85%'}}>
          <span className="formLabel">Url immagine</span>
          <p className="lead pr-5">{imageUrl || '-'}</p>
        </div>
      </div>
      <div className="mb-3">
        <ImagePreview imageUrl={imageUrl} size={120} />
      </div>
      <div>
        <span className="formLabel">Interessi</span>
        {!!campaignTopics && campaignTopics.length > 0 ? (
          campaignTopics.map((topic, index) => (
            <p
              className={clsx('lead', 'mb-0', {'mt-0': index !== 0})}
              key={topic.id}>
              <TopicBadge id={topic.id} topics={topics} />
            </p>
          ))
        ) : (
          <p className="lead">-</p>
        )}
      </div>
      <span className="formLabel mt-4">Data e ora di invio</span>
      <p className="lead">{date}</p>
      {!isNewCampaign && (
        <>
          <span className="formLabel">Stato</span>
          <p className="lead">
            <Status status={status} enableDescription />
          </p>
        </>
      )}
    </Card.Body>
  )
}
