import React, {useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
import BackButton from '../BackButton'
import {scrollToTop} from '../../utilities/layout-utils'
import OperatorEditTitle from './OperatorEditTitle'
import OperatorEditForm from './OperatorEditForm'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  container: {
    marginLeft: theme.spacing(4),
    maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  verticalSpacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}))

function OperatorEdit(props) {
  const classes = useStyles()
  const {
    userData = {},
    onBack,
    onEnable,
    onDisable,
    loadingUser = true,
    savingUser,
    onSave,
  } = props

  useLayoutEffect(scrollToTop, [])

  if (loadingUser) {
    // console.log('still loading')
    return null
  }
  const formDisabled = loadingUser || savingUser
  // console.log('loaded')

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, classes.verticalSpacing)}>
        <BackButton onBack={onBack} />
      </div>
      <div className={classes.container}>
        <OperatorEditTitle
          userDisabled={userData.disabled}
          buttonDisabled={formDisabled}
          onEnable={onEnable}
          onDisable={onDisable}
        />
      </div>
      <div className={classes.container}>
        <OperatorEditForm userData={userData} onSave={onSave} />
      </div>
    </div>
  )
}

OperatorEdit.propTypes = {
  onBack: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userData: PropTypes.object,
  loadingUser: PropTypes.bool,
  errorUser: PropTypes.object,
  savingUser: PropTypes.bool,
  errorSaving: PropTypes.object,
  saveResponse: PropTypes.any,
}

export default OperatorEdit
