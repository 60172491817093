import React, {useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
import BackButton from '../BackButton'
import {scrollToTop} from '../../utilities/layout-utils'
import OperatorNewTitle from './OperatorNewTitle'
import OperatorNewForm from './OperatorNewForm'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  container: {
    marginLeft: theme.spacing(4),
    maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  verticalSpacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}))

function OperatorNew(props) {
  const classes = useStyles()
  const {
    userData = {},
    onBack,
    // savingUser,
    onSave,
  } = props

  useLayoutEffect(scrollToTop, [])

  // const formDisabled = savingUser

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, classes.verticalSpacing)}>
        <BackButton onBack={onBack} />
      </div>
      <div className={classes.container}>
        <OperatorNewTitle />
      </div>
      <div className={classes.container}>
        <OperatorNewForm userData={userData} onSave={onSave} />
      </div>
    </div>
  )
}

OperatorNew.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userData: PropTypes.object,
  loadingUser: PropTypes.bool,
  errorUser: PropTypes.object,
  savingUser: PropTypes.bool,
  errorSaving: PropTypes.object,
  saveResponse: PropTypes.any,
}

export default OperatorNew
