import React, {useState, useEffect} from 'react'
import {apiBaseUrl, normalApiTimeout} from '../Config'
import axios from 'axios'
import {Auth} from 'aws-amplify'

const ContactReasonsCacheContext = React.createContext([[], () => {}])

const ContactReasonsCacheProvider = (props) => {
  const [state, setState] = useState([])

  useEffect(() => {
    async function retrieveContactReasons() {
      const session = await Auth.currentSession()
      const token = session.getAccessToken().getJwtToken()
      if (token == null) {
        console.error('contact reasons cache, not authenticated')
        return
      }
      const result = await axios({
        url: `${apiBaseUrl}/gbp/contact-reasons`,
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`},
        timeout: normalApiTimeout,
      })
      // console.log(
      //   'Contact reasons cache',
      //   result?.data?.success ? result.data.data : result
      // )
      setState(result?.data?.success ? result.data.data : [])
    }
    retrieveContactReasons()
  }, [])

  return (
    <ContactReasonsCacheContext.Provider value={[state, setState]}>
      {props.children}
    </ContactReasonsCacheContext.Provider>
  )
}

export {ContactReasonsCacheContext, ContactReasonsCacheProvider}
