import React from 'react'
import {Spinner} from 'react-bootstrap'
import Img from 'react-image'
import * as Icons from 'react-feather'

const getStyles = (side = 'none', size = 60) => ({
  previewImage: {
    float: side,
    width: size,
    height: size,
    borderRadius: 3,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  loader: {
    float: side,
    width: size,
    height: size,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unloader: {
    float: side,
    width: size,
    height: size,
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    color: 'red',
  },
})

function Loader({styles}) {
  return (
    <div style={styles.loader}>
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  )
}

function Unloader({styles}) {
  return (
    <div style={styles.unloader}>
      <Icons.XOctagon />
    </div>
  )
}

export default function ImagePreview(props) {
  const {imageUrl, side = 'none', size = 60} = props
  const styles = getStyles(side, size)
  if (!imageUrl) {
    return null
  }
  return (
    <Img
      src={imageUrl}
      style={styles.previewImage}
      // crossorigin="anonymous"
      loader={<Loader styles={styles} />}
      unloader={<Unloader styles={styles} />}
    />
  )
}
