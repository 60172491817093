import React from 'react'
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Routing from '../../Routing'
import {ContactReasonsCacheProvider} from '../../Contexts/ContactReasonsCacheContext'
import {CustomerTypologiesCacheProvider} from '../../Contexts/CustomerTypologiesCaceContext'
import {OperatorsCacheProvider} from '../../Contexts/OperatorsCacheContext'
import Amplify, {I18n} from 'aws-amplify'
import {
  AmplifyProvider,
  Authenticator,
  translations,
  defaultTheme,
} from '@aws-amplify/ui-react'
import Typography from '@material-ui/core/Typography'

import '@aws-amplify/ui-react/styles.css'
import './App.css'
import AuthContainer from '../AuthContainer'
I18n.putVocabularies(translations)
I18n.setLanguage('it')
I18n.putVocabulariesForLanguage('it', {
  // 'Sign In': 'Login', // Tab header
  // 'Sign in': 'Log in', // Button label
  // 'Sign in to your account': 'Welcome Back!',
  // Username: 'Enter your username', // Username label
  // Password: 'Enter your password', // Password label
  // 'Forgot your password?': 'Reset Password',
  // 'Reset your password': 'Forgot your password?',
  'Signing in': 'Login in corso',
  'Enter your email': 'Inserisci la tua Email',
  'Send code': 'Invia codice',
  // 'Back to Sign In': 'Back to Login',
})

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_ZONE,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //     domain: 'your_cognito_domain',
    //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //     redirectSignIn: 'http://localhost:3000/',
    //     redirectSignOut: 'http://localhost:3000/',
    //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
  },
})

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#003C6E',
    },
    secondary: {
      main: '#FBAB50',
    },
    background: {
      // paper: '#fff',
      default: '#fff',
    },
  },
})

/*

--amplify-colors-brand-primary-100: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-90);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-teal-60);
  --amplify-colors-brand-primary-40: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-20: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-10);
*/

const amplifyTheme = {
  name: 'my-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          100: {value: defaultTheme.tokens.colors.blue[100].value},
          90: {value: defaultTheme.tokens.colors.blue[90].value},
          80: {value: defaultTheme.tokens.colors.blue[80].value},
          60: {value: defaultTheme.tokens.colors.blue[60].value},
          40: {value: defaultTheme.tokens.colors.blue[40].value},
          20: {value: defaultTheme.tokens.colors.blue[20].value},
          10: {value: defaultTheme.tokens.colors.blue[10].value},
        },
      },
    },
    components: {
      button: {
        link: {
          color: {value: '#003C6E'},
        },
        primary: {
          hover: {
            background: {
              color: {value: 'rgb(0, 42, 77)'},
            },
          },
          background: {
            color: {value: '#003C6E'},
          },
        },
      },
      authenticator: {
        router: {
          border: {
            width: {value: 0},
          },
          box: {
            shadow: {value: 0},
          },
        },
      },
    },
  },
}

function App() {
  return (
    <ContactReasonsCacheProvider>
      <CustomerTypologiesCacheProvider>
        <OperatorsCacheProvider>
          <CssBaseline />
          <Routing />
        </OperatorsCacheProvider>
      </CustomerTypologiesCacheProvider>
    </ContactReasonsCacheProvider>
  )
}

const components = {
  Header() {
    return <AuthContainer title="Login" />
  },
  SignIn: {
    Header() {
      return <Typography variant="h4">Login</Typography>
    },
  },
}

export default function AppWrapper() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AmplifyProvider theme={amplifyTheme}>
        {/* <AuthContainer title="Login"> */}
        <div>
          <Authenticator
            loginMechanisms={['email']}
            hideSignUp={true}
            components={components}>
            {() => {
              // console.log(user)
              return <App />
            }}
          </Authenticator>
        </div>
        {/* </AuthContainer> */}
      </AmplifyProvider>
    </MuiThemeProvider>
  )
}
