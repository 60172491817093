import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {format} from 'date-fns'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minWidth: 650,
  },
  columnPadding: {
    paddingLeft: theme.spacing(4),
  },
}))

function groupCheckins(checkins = []) {
  const groupedObj = checkins
    .map(({serverTimestamp, customer}) => ({serverTimestamp, ...customer}))
    .reduce((acc, value) => {
      const {customerId: key, serverTimestamp} = value
      if (acc[key]) {
        acc[key].checkins = acc[key].checkins + 1
        const lastDate = new Date(acc[key].lastCheckin)
        const currDate = new Date(serverTimestamp)
        if (lastDate.getTime() < currDate.getTime()) {
          acc[key].lastCheckin = serverTimestamp
        }
      } else {
        acc[key] = {...value, checkins: 1, lastCheckin: serverTimestamp}
      }
      return acc
    }, {})
  let groupedCheckins = Object.values(groupedObj)
  groupedCheckins.sort((a, b) => {
    const aDate = new Date(a.lastCheckin)
    const bDate = new Date(b.lastCheckin)
    return bDate.getTime() - aDate.getTime()
  })
  return groupedCheckins
}

export default function LastPositions({checkins = []}) {
  const classes = useStyles()
  // if (checkins.length === 0) {
  //   return null
  // }
  const groupedCheckins = groupCheckins(checkins)

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnPadding}>Cliente</TableCell>
            <TableCell>Indirizzo</TableCell>
            {/* <TableCell>Latitudine</TableCell> */}
            {/* <TableCell>Longitude</TableCell> */}
            <TableCell align="right">N. Check-In</TableCell>
            <TableCell align="center">Ultimo Check-In</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedCheckins.map((row) => (
            <TableRow key={row.customerId}>
              <TableCell
                className={classes.columnPadding}
                component="th"
                scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.address}</TableCell>
              {/* <TableCell>{row.latitude}</TableCell> */}
              {/* <TableCell>{row.longitude}</TableCell> */}
              <TableCell align="right">{row.checkins}</TableCell>
              <TableCell align="center">
                {row.lastCheckin
                  ? format(new Date(row.lastCheckin), 'dd/MM/yyyy - HH:mm')
                  : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
