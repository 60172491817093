import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useNavigate} from 'react-router-dom'
import GbpCustomersList from './GbpCustomersList'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import useAxios from 'axios-hooks'
import {useAuthenticator} from '@aws-amplify/ui-react'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
    // marginTop: '3px',
    // backgroundColor: 'red',
    // position: 'relative',
  },
}))

// avoid browser cache
let now = Date.now()

function GbpCustomers() {
  const classes = useStyles()
  const navigate = useNavigate()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  React.useEffect(() => {
    now = Date.now()
  }, [])
  const [{data: responseData, loading: fetching, error: requestError}] =
    useAxios({
      url: `${apiBaseUrl}/gbp/customers?fakeTimestamp=${now}`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    })

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <GbpCustomersList
          rows={responseData || []}
          onCustomerDetail={(id) => navigate(`/gbpeople/${id}`)}
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <ErrorSnackbar
        response={responseData}
        error={requestError}
        errorMessage={'Impossibile caricare la lista dei clienti GbPeople'}
      />
    </div>
  )
}

export default GbpCustomers
