import React from 'react'
// import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import useAxios from 'axios-hooks'
import {useParams, useNavigate} from 'react-router-dom'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import {useAuthenticator} from '@aws-amplify/ui-react'
import OperatorDetail from './OperatorDetail'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'
import SaveSnackbar from '../CustomSnackbar/SaveSnackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
  },
}))

// avoid browser cache
let now = Date.now()

function OperatorDetailPage() {
  const classes = useStyles()
  const navigate = useNavigate()
  const {userId} = useParams()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  const [{data: userData, loading: loadingUser, error: errorUser}] = useAxios({
    url: `${apiBaseUrl}/users/${userId}?fakeTimestamp=${now}`,
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    timeout: normalApiTimeout,
  })
  if (!loadingUser && !errorUser) {
    userData.isVerified =
      userData?.cognito?.UserStatus !== 'FORCE_CHANGE_PASSWORD'
  }

  React.useEffect(() => {
    now = Date.now()
  }, [])

  const [
    {
      data: lastPositionsResponse = [],
      loading: loadingLastPositions,
      error: errorLastPositions,
    },
  ] = useAxios({
    url: `${apiBaseUrl}/positions?limit=3&userId=${userId}&minimal=true&fakeTimestamp=${now}`,
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    timeout: normalApiTimeout,
  })

  const [
    {data: lastStates, loading: loadingLastStates, error: errorLastStates},
  ] = useAxios({
    url: `${apiBaseUrl}/states?limit=10&userId=${userId}&fakeTimestamp=${now}`,
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    timeout: normalApiTimeout,
  })

  const [{data: checkins, loading: loadingCheckins, error: errorCheckins}] =
    useAxios({
      url: `${apiBaseUrl}/checkins?userId=${userId}&fakeTimestamp=${now}`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    })

  const [
    {data: resendResponse, loading: resendingInvitation, error: errorResending},
    executeResendInvitation,
  ] = useAxios(
    {
      url: `${apiBaseUrl}/reinvite`,
      method: 'POST',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    },
    {manual: true}
  )

  const fetching =
    loadingUser ||
    loadingLastPositions ||
    loadingCheckins ||
    loadingLastStates ||
    resendingInvitation

  let lastPositions = []
  if (
    lastPositionsResponse &&
    !loadingLastPositions &&
    !errorLastPositions &&
    lastPositionsResponse.length > 0
  ) {
    lastPositions = lastPositionsResponse[0].lastPositions
  }

  const onResend = async () => {
    await executeResendInvitation({
      data: {userId},
    })
  }

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <OperatorDetail
          userData={userData}
          loadingUser={loadingUser}
          lastPositions={lastPositions}
          checkins={checkins}
          lastStates={lastStates}
          onResend={onResend}
          fetching={fetching}
          onBack={() => navigate('/operators')}
          onEdit={() => navigate(`/operators/${userId}/edit`)}
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <ErrorSnackbar
        response={userData}
        error={
          errorUser || errorLastPositions || errorLastStates || errorCheckins
        }
        errorMessage={"Impossibile caricare tutti i dati dell'utente"}
      />
      <SaveSnackbar
        response={resendResponse}
        error={errorResending}
        successMessage="Invito reinviato correttamente"
        errorMessage="Errore durante l'invio dell'invito"
      />
    </div>
  )
}

OperatorDetailPage.propTypes = {}

export default OperatorDetailPage
