/**
 * Expo map polling seconds to get updated operators positions
 */
export const expoMapPollingSeconds = 60

/**
 * Admin map polling seconds to get updated operators positions
 */
export const adminMapPollingSeconds = 60

/**
 * Map init latitude
 */
export const mapDefaultLatitude = 42.847275
/**
 * Map init longitude
 */
export const mapDefaultLongitude = 13.662406

/**
 * Map init position
 */
export const mapDefaultCenter = Object.freeze({
  lat: mapDefaultLatitude,
  lng: mapDefaultLongitude,
})

/**
 * Express, in minutes, the amount of time, during work hour, after which an operator is
 * considered unreachable if no data received
 */
export const unreachableMinutesLimit = 15
/**
 * The lower limit, in Km, to consider an operator as "moving".
 * If 2 last positions differ lesser than movingStatusKmLimit the operator is considered stopped.
 */
export const movingStatusKmLimit = 1
export const movingStatusMinutesLimit = 40

/**
 * This interval represents the amount of seconds to wait before redraw the map and recalculate and apply the
 * maximum map zoom value including all the pins on map.
 * Used only in expo map, default to 900 (15 minutes).
 */
export const mapFitBoundsSecondsInterval = 900

export const apiBaseUrl = process.env.REACT_APP_API
export const normalApiTimeout = 10000
export const longApiTimeout = 30000

export const Colors = Object.freeze({
  BLUE: 'blue',
  BROWN: 'brown',
  CYAN: 'cyan',
  GREEN: 'green',
  ORANGE: 'orange',
  PINK: 'pink',
  PURPLE: 'purple',
  RED: 'red',
  TEAL: 'teal',
  YELLOW: 'yellow',
  GREY: 'grey',
})

/**
 * Enumeration of user types. OPERATOR is deprecated.
 */
export const UserClass = Object.freeze({
  ADMINISTRATOR: 'administrator',
  EXHIBITOR: 'exhibitor',
  OPERATOR: 'operator',
  MOBILE: 'mobile',
})

export const PositionState = Object.freeze({
  START: 'start',
  STOP: 'stop',
})

export const UserSpecializations = Object.freeze([
  'Consulenza Alimentare e HACCP',
  'Campionamento Ambientale',
  'Consulenza Ambiente e Sicurezza',
])

/**
 * test user to be ignored in expo map in production
 */
export const ExpoIgnoredUsersId = Object.freeze([
  '261f2a76-8755-4764-9166-7d09e33d8a17',
  '291afb5c-6670-405b-8b46-b0bb7250a803',
  '355952ce-8326-4987-9489-d94c618f7ab3',
  '553f24f7-c194-4b22-9c04-baec37e3f697',
  'df54156b-6edc-4d85-9705-34ac00e3f195',
  'e01a6c9c-4ae9-47b8-bd3b-132f5149dbcf',
])
