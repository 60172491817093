import React, {createContext, useState} from 'react'

const initialState = {
  osStats: [],
  topicsStats: [],
  singleTopic: null,
  osRequest: {
    fetching: false,
    error: null,
  },
  topicsRequest: {
    fetching: false,
    error: null,
  },
  addTopicRequest: {
    success: false,
    fetching: false,
    error: null,
  },
  editTopicRequest: {
    success: false,
    fetching: false,
    error: null,
  },
  singleTopicRequest: {
    fetching: false,
    error: null,
  },
}

const TopicsContext = createContext([{}, () => {}])
const TopicsProvider = (props) => {
  const [state, setState] = useState(initialState)
  return (
    <TopicsContext.Provider value={[state, setState]}>
      {props.children}
    </TopicsContext.Provider>
  )
}

export {TopicsContext, TopicsProvider}
