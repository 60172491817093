import React, {useState} from 'react'
import {Accordion} from 'react-bootstrap'
import StepContent from './NewCampaignStepContent'
import StepTarget from './NewCampaignStepTarget'
import StepScheduler from './NewCampaignStepScheduler'
import StepRecap from './NewCampaignStepRecap'

export default function StepWrapper(props) {
  const [activeKey, setActiveKey] = useState('target')
  const commonProps = {setActiveKey, activeKey, ...props}
  return (
    <Accordion activeKey={activeKey} className="newCampaign">
      <StepTarget {...commonProps} />
      <StepContent {...commonProps} />
      <StepScheduler {...commonProps} />
      <StepRecap {...commonProps} />
    </Accordion>
  )
}
