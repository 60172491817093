import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import useAxios from 'axios-hooks'
import {useParams, useNavigate} from 'react-router-dom'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import {useAuthenticator} from '@aws-amplify/ui-react'
import OperatorEdit from './OperatorEdit'
import SaveSnackbar from '../CustomSnackbar/SaveSnackbar'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
  },
}))

// avoid browser cache
let now = Date.now()

function OperatorEditPage(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const {userId} = useParams()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  React.useEffect(() => {
    now = Date.now()
  }, [])
  const [{data: userRawData, loading: loadingUser, error: errorUser}] =
    useAxios({
      url: `${apiBaseUrl}/users/${userId}?fakeTimestamp=${now}`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    })

  const [
    {data: saveResponse, loading: savingUser, error: errorSaving},
    executeSave,
  ] = useAxios(
    {
      url: `${apiBaseUrl}/users/${userId}`,
      method: 'PATCH',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    },
    {manual: true}
  )

  let userData = userRawData
  if (userRawData) {
    const rawData =
      saveResponse && saveResponse.success ? saveResponse.user : userRawData
    const {workHour = {}, color, disabled = false, ...user} = rawData
    // const {startHour, startMinute, endHour, endMinute} = workHour
    try {
      const startHour = Number.parseInt(workHour.startHour, 10)
      const startMinute = Number.parseInt(workHour.startMinute, 10)
      const endHour = Number.parseInt(workHour.endHour, 10)
      const endMinute = Number.parseInt(workHour.endMinute, 10)
      userData = {
        ...user,
        disabled,
        color: disabled ? 'grey' : color,
        startHour,
        startMinute,
        endHour,
        endMinute,
      }
    } catch (error) {
      userData = {
        ...user,
        disabled,
        color: disabled ? 'grey' : color,
        startHour: null,
        startMinute: null,
        endHour: null,
        endMinute: null,
      }
    }
  }

  const onDisable = async () => {
    const {email, name, surname, workHour} = userRawData
    executeSave({
      data: {email, name, surname, workHour, disabled: true},
    })
  }
  const onEnable = async () => {
    const {email, name, surname, workHour} = userRawData
    executeSave({
      data: {email, name, surname, workHour, disabled: false},
    })
  }

  const onSave = async (flatData, formikBag) => {
    const toPaddedString = (n) => (n < 10 ? `0${n}` : `${n}`)
    const {
      startHour,
      startMinute,
      endHour,
      endMinute,
      color,
      email,
      name,
      surname,
      disabled,
      phoneNumber,
      specialization,
      isGbPeopleExposable,
    } = flatData
    await executeSave({
      data: {
        email,
        color,
        name,
        surname,
        phoneNumber,
        specialization,
        isGbPeopleExposable,
        disabled,
        workHour: {
          startHour: toPaddedString(startHour),
          startMinute: toPaddedString(startMinute),
          endHour: toPaddedString(endHour),
          endMinute: toPaddedString(endMinute),
        },
      },
    })
    formikBag.setSubmitting(false)
  }
  const fetching = loadingUser || savingUser

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <OperatorEdit
          savingUser={savingUser}
          userData={userData}
          loadingUser={loadingUser}
          onDisable={onDisable}
          onEnable={onEnable}
          onSave={onSave}
          onBack={() => navigate(`/operators/${userId}`)}
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <SaveSnackbar response={saveResponse} error={errorSaving} />
      <ErrorSnackbar
        response={userRawData}
        error={errorUser}
        errorMessage={"Impossibile caricare tutti i dati dell'utente"}
      />
    </div>
  )
}

OperatorEditPage.propTypes = {}

export default OperatorEditPage
