import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import ResetPasswordForm from './ResetPasswordForm'
import AuthContainer from '../AuthContainer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import useAxios from 'axios-hooks'
import LinearProgress from '@material-ui/core/LinearProgress'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'
import qs from 'qs'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

function ResetPassword() {
  const location = useLocation()
  const navigate = useNavigate()
  const {email, token} = qs.parse(location.search, {ignoreQueryPrefix: true})
  const [
    {
      // data: checkTokenResponseData,
      loading: checkingToken,
      error: checkTokenError,
      // response: checkTokenResponse,
    },
  ] = useAxios({
    url: `${apiBaseUrl}/confirm/${token}?email=${email}&type=reset`,
    method: 'GET',
    timeout: normalApiTimeout,
  })
  const [
    {
      data: resetData,
      loading: resetting,
      error: resetError,
      // response: resetResponse,
    },
    executeReset,
  ] = useAxios(
    {
      url: `${apiBaseUrl}/reset-password`,
      method: 'POST',
      timeout: normalApiTimeout,
    },
    {manual: true}
  )

  let checkTokenErrorMsg = ''
  if (checkTokenError) {
    const {response = {}} = checkTokenError
    const {status, data = {}} = response
    const {message} = data
    checkTokenErrorMsg =
      status === 404
        ? `Nessun utente esistente per l'email ${email}`
        : status === 400 && message === 'Token is expired.'
        ? 'La richiesta è scaduta. Richiedere un nuovo invio email per reimpostare la password.'
        : 'La richiesta non è valida. Richiedere un nuovo invio email per reimpostare la password.'
  }

  let snackbarErrorMessage = 'Errore durante il tentativo di registrazione'
  if (resetError) {
    const {response = {}} = resetError
    const {status, data = {}} = response
    const {message} = data
    if (status === 400 && message === 'Token no more valid.') {
      snackbarErrorMessage = 'Token non valido o scaduto'
    }
  }

  async function handleReset(password) {
    executeReset({
      data: {email, type: 'reset', password, resetToken: token},
    })
  }

  if (checkingToken) {
    return <LinearProgress />
  }

  return (
    <div>
      {resetting && <LinearProgress />}
      <AuthContainer title="Reset Password">
        <ResetPasswordForm
          onReset={handleReset}
          loading={resetting}
          email={email}
          disabled={resetting}
        />
      </AuthContainer>
      <ErrorSnackbar
        response={resetData}
        error={resetError}
        errorMessage={snackbarErrorMessage}
      />
      <Dialog
        open={checkTokenError || !!resetData || !email || !token}
        onClose={() => navigate('/login')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {!email || !token || checkTokenError ? 'Errore' : 'Successo'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!email
              ? 'Impossibile impostare credenziali in mancanza di email'
              : !token
              ? 'Impossibile impostare credenziali in mancanza di token'
              : !!checkTokenErrorMsg
              ? checkTokenErrorMsg
              : 'Credenziali impostate con successo, ora si può procedere alla schermata di login'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate('/login')} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResetPassword
