import React, {useEffect} from 'react'
// import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Formik} from 'formik'
import validationSchema from './OperatorNewValidation'
import {colorsToValueLabelPair} from '../../utilities/user-transformers'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Ball from '../Ball'
import {UserSpecializations} from '../../Config'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200,
    // marginTop: '31px'
  },
  selectField: {
    minWidth: 150,
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    marginBottom: theme.spacing(3),
  },
  button: {
    // margin: theme.spacing(1),
    minWidth: theme.spacing(34),
  },
  hourField: {
    minWidth: theme.spacing(7),
    marginRight: theme.spacing(2),
    // textAlign: 'right',
  },
  minuteField: {
    minWidth: theme.spacing(7),
    marginRight: theme.spacing(2),
    // textAlign: 'right',
  },
  gridItem: {
    // backgroundColor: 'green',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

function InnerForm(props) {
  const classes = useStyles()
  const {
    values: {
      name,
      surname,
      email,
      color,
      startHour,
      endHour,
      startMinute,
      endMinute,
      phoneNumber,
      isGbPeopleExposable,
      specialization,
    },
    errors,
    touched,
    dirty,
    handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    validateForm,
    /* and other goodies */
  } = props

  useEffect(() => {
    validateForm()
  }, [validateForm])

  const colorsItems = colorsToValueLabelPair()

  return (
    <form
      className={classes.container}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}>
      {/* <Typography variant="h5" className={classes.title}>
        Orario di lavoro
      </Typography> */}
      <Grid
        container
        alignItems="center"
        className={classes.gridContainer}
        spacing={3}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            id="name"
            label="Nome"
            className={classes.textField}
            margin="normal"
            fullWidth
            value={name}
            onChange={handleChange}
            error={!!touched.name && !!errors.name}
            helperText={errors.name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            id="surname"
            label="Cognome"
            className={classes.textField}
            margin="normal"
            fullWidth
            value={surname}
            onChange={handleChange}
            error={!!touched.surname && !!errors.surname}
            helperText={errors.surname || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            id="email"
            name="email"
            label="Email"
            className={classes.textField}
            margin="normal"
            fullWidth
            value={email}
            onChange={handleChange}
            error={!!touched.email && !!errors.email}
            helperText={errors.email || ''}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            id="phoneNumber"
            label="Numero di telefono"
            className={classes.textField}
            margin="normal"
            fullWidth
            value={phoneNumber}
            onChange={handleChange}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber || ''}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            id="specialization"
            name="specialization"
            label="Specializzazione"
            className={classes.selectField}
            margin="normal"
            select
            fullWidth
            value={specialization}
            onChange={handleChange}
            error={errors.specialization}
            helperText={errors.specialization}>
            {[null, ...UserSpecializations].map((value) => (
              <MenuItem key={value} value={value}>
                {value || 'nessuna'}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl
            className={classes.formControl}
            error={!!errors.color}
            margin="normal"
            fullWidth>
            <InputLabel htmlFor="color">Colore</InputLabel>
            <Select
              value={color}
              onChange={handleChange}
              name="color"
              input={<Input id="color" />}>
              {colorsItems.map(({value, label}) => (
                <MenuItem key={value} value={value}>
                  <Ball color={value} className={classes.icon} />
                  {' ' + label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.color || ''}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <FormControlLabel
            control={
              <Switch
                checked={isGbPeopleExposable}
                onChange={handleChange}
                name="isGbPeopleExposable"
                color="primary"
              />
            }
            label="Visibile in GbPeople"
            // labelPlacement="start"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer} spacing={3}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography variant="h6" display="block">
            Inizio orario di lavoro
          </Typography>
          <TextField
            id="startHour"
            name="startHour"
            label="Ore"
            className={classes.hourField}
            margin="normal"
            select
            value={startHour}
            onChange={handleChange}
            error={errors.startHour}
            helperText={errors.startHour}>
            {[8, 9, 10].map((value) => (
              <MenuItem key={value} value={value}>
                {value >= 10 ? `${value}` : `0${value}`}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="startMinute"
            name="startMinute"
            label="Minuti"
            className={classes.minuteField}
            margin="normal"
            select
            value={startMinute}
            onChange={handleChange}
            error={errors.startMinute}
            helperText={errors.startMinute}>
            <MenuItem value={0}>00</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography variant="h6" display="block">
            Fine orario di lavoro
          </Typography>
          <TextField
            id="endHour"
            name="endHour"
            label="Ore"
            className={classes.hourField}
            margin="normal"
            select
            value={endHour}
            onChange={handleChange}
            error={errors.endHour}
            helperText={errors.endHour}>
            {[17, 18, 19, 20].map((value) => (
              <MenuItem key={value} value={value}>
                {value >= 10 ? `${value}` : `0${value}`}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="endMinute"
            name="endMinute"
            label="Minuti"
            className={classes.minuteField}
            margin="normal"
            select
            value={endMinute}
            onChange={handleChange}
            error={errors.endMinute}
            helperText={errors.endMinute}>
            <MenuItem value={0}>00</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={isSubmitting || !dirty}
        // disabled={isSubmitting}
        type="submit">
        Salva modifiche
      </Button>
    </form>
  )
}

function Form(props) {
  const {onSave, userData} = props

  return (
    <Formik
      component={InnerForm}
      initialValues={userData}
      validationSchema={validationSchema}
      onSubmit={onSave}
      enableReinitialize
    />
  )
}

export default Form
