import React from 'react'
import PropTypes from 'prop-types'
import {Pagination} from 'react-bootstrap'

CustomPagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
}

export default function CustomPagination(props) {
  const {
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
  } = props
  if (pageCount <= 1) {
    return null
  }
  const allItems = new Array(pageCount).fill(0).map((_, index) => (
    <Pagination.Item
      key={index}
      onClick={() => gotoPage(index)}
      active={index === pageIndex}>
      {index + 1}
    </Pagination.Item>
  ))
  let rangeItems
  if (pageIndex < 3) {
    rangeItems = allItems.slice(0, 5)
  } else if (pageIndex > pageCount - 3) {
    rangeItems = allItems.slice(-5)
  } else {
    rangeItems = allItems.slice(pageIndex - 2, pageIndex + 3)
  }
  return (
    <Pagination>
      <Pagination.First
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      />
      <Pagination.Prev
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      />
      {rangeItems}
      <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
      <Pagination.Last
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      />
    </Pagination>
  )
}
