import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {format} from 'date-fns'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minWidth: 650,
  },
}))

export default function LastPositions({lastPositions = []}) {
  const classes = useStyles()
  // if (lastPositions.length === 0) {
  //   return null
  // }

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell align="center">Orario</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lastPositions.map((row) => (
            <TableRow key={row.serverTimestamp}>
              <TableCell component="th" scope="row">
                {format(new Date(row.serverTimestamp), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell align="center">
                {format(new Date(row.serverTimestamp), 'HH:mm')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
