import React from 'react'
import PropTypes from 'prop-types'
import * as Icons from 'react-feather'
import {Button, Form} from 'react-bootstrap'
// import useInput from '../../CustomHooks/useInput'
import CampaignsTable from '../CampaignsTable'
import CampaignsStats from './CampaignsStats'
import CampaignsEmpty from './CampaignsEmpty'
// import Breadcrumb from '../DynamicBreadcrumb'
import Typography from '@material-ui/core/Typography'

CampaignsComponent.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  toNewCampaign: PropTypes.func.isRequired,
  data: PropTypes.array,
  topics: PropTypes.array,
}

function CampaignsComponent(props) {
  const {handleSearch, toNewCampaign, data, topics, fetching = false} = props
  // const {value: searchText, bind: bindSearch} = useInput('')
  // const handleSubmit = evt => {
  //   evt.preventDefault()
  //   handleSearch(searchText)
  // }
  return (
    <React.Fragment>
      {/* <Breadcrumb> */}
      <Button
        onClick={toNewCampaign}
        variant="primary"
        className="mr-0 text-nowrap float-right"
        // size="sm"
      >
        <Icons.Target className="mr-2" />
        NUOVA CAMPAGNA
      </Button>
      {/* </Breadcrumb> */}
      <div className="d-flex justify-content-between pt-0 pb-0 mb-3">
        <Typography variant="h5">Campagne</Typography>
      </div>

      {!fetching && data && data.length === 0 ? (
        <CampaignsEmpty />
      ) : (
        <CampaignsStats {...props} />
      )}

      <Form
        className="form-inline d-flex justify-content-between mt-5"
        // onSubmit={handleSubmit}
        onSubmit={(e) => e.preventDefault()}>
        <div className="d-flex align-items-center">
          <img
            className="mr-3"
            src="/imgs/search.svg"
            alt="Cerca"
            width="18"
            height="18"
          />
          <Form.Group className="form-group my-2">
            <Form.Control
              className="form-control col-12"
              type="search"
              id="searchInput"
              placeholder="Cerca"
              size="sm"
              // {...bindSearch}
              defaultValue=""
              onChange={(evt) => handleSearch(evt.target.value)}
            />
            {/* <Button type="submit" className="btn-primary mx-2 blueBg" size="sm">
            Search
          </Button> */}
          </Form.Group>
        </div>
      </Form>

      <CampaignsTable campaigns={data} enableProgressive topics={topics} />
    </React.Fragment>
  )
}

export default CampaignsComponent
