import React from 'react'
import PropTypes from 'prop-types'
import {Card, Accordion, Button, Form} from 'react-bootstrap'

StepTarget.propTypes = {
  setActiveKey: PropTypes.func.isRequired,
  bindOs: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  bindTopic: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
}

export default function StepTarget(props) {
  const {setActiveKey, bindOs, bindTopic, topics = []} = props
  const handleSubmit = (evt) => {
    evt.preventDefault()
    setActiveKey('content')
  }
  const {value: osValue, onChange: onOsChange} = bindOs
  const {value: topicValue, onChange: onTopicChange} = bindTopic
  function handleOsChange(event) {
    onOsChange(event)
    const {value} = event.target
    if (topicValue && value.split(',').length < 2) {
      onTopicChange({target: {value: ''}})
    }
  }
  function handleTopicChange(event) {
    onTopicChange(event)
    const {value} = event.target
    if (osValue.length < 2 && value) {
      onOsChange({target: {value: 'ios,android'}})
    }
  }
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="target"
          onClick={() => setActiveKey('target')}>
          <span className="stepActive mr-3">1</span>Target
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="target">
        <Card.Body className="ml-4">
          <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-row ">
              <Form.Group controlId="exampleForm.OsList" className="pr-5">
                <Form.Label className="formLabel">Sistema Operativo</Form.Label>

                <Form.Control
                  as="select"
                  value={Array.isArray(osValue) ? osValue.join(',') : osValue}
                  onChange={handleOsChange}>
                  <option value={'ios,android'}>iOS + Android</option>
                  <option value={'android'}>Android</option>
                  <option value={'ios'}>iOS</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="exampleForm.TopicsList">
                <Form.Label className="formLabel">Interesse</Form.Label>
                <Form.Control
                  as="select"
                  value={topicValue}
                  onChange={handleTopicChange}>
                  <option value="">--</option>
                  {topics.map(({id, name}) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between pt-4">
              <Button disabled className="btn-primary" size="lg">
                indietro
              </Button>
              <Button type="submit" className="btn-primary" size="lg">
                avanti
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}
