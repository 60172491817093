import React, {useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
import BackButton from '../BackButton'
import {scrollToTop} from '../../utilities/layout-utils'
import CustomerEditTitle from './CustomerEditTitle'
import CustomerEditForm from './CustomerEditForm'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  container: {
    marginLeft: theme.spacing(4),
    maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  verticalSpacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}))

function OperatorEdit(props) {
  const classes = useStyles()
  const {
    customerData = {},
    onBack,
    onEnable,
    onDisable,
    loadingCustomer = true,
    savingCustomer,
    onSave,
  } = props

  useLayoutEffect(scrollToTop, [])

  if (loadingCustomer) {
    // console.log('still loading')
    return null
  }
  const formDisabled = loadingCustomer || savingCustomer
  // console.log('loaded')

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, classes.verticalSpacing)}>
        <BackButton onBack={onBack} />
      </div>
      <div className={classes.container}>
        <CustomerEditTitle
          customerDisabled={customerData.disabled}
          buttonDisabled={formDisabled}
          onEnable={onEnable}
          onDisable={onDisable}
        />
      </div>
      <div className={classes.container}>
        <CustomerEditForm customerData={customerData} onSave={onSave} />
      </div>
    </div>
  )
}

OperatorEdit.propTypes = {
  onBack: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  customerData: PropTypes.object,
  loadingCustomer: PropTypes.bool,
  errorCustomer: PropTypes.object,
  savingCustomer: PropTypes.bool,
  errorSaving: PropTypes.object,
  saveResponse: PropTypes.any,
}

export default OperatorEdit
