import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {format} from 'date-fns'
import {it} from 'date-fns/locale'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minWidth: 650,
  },
  columnPadding: {
    paddingLeft: theme.spacing(4),
  },
}))

function ContactedOps(props) {
  const classes = useStyles()
  const {contactedOps = [], operators = [], contactReasons = []} = props

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Operatore contattato</TableCell>
            <TableCell>Canale di contatto</TableCell>
            <TableCell>Motivo del contatto</TableCell>
            <TableCell className={classes.columnPadding}>In data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contactedOps.map((row) => {
            const operator = operators.find(
              (op) => op.userId === row.operatorId
            )
            const opLabel =
              operator == null
                ? operators.length === 0
                  ? '-'
                  : 'operatore rimosso'
                : `${operator.name} ${operator.surname}`
            if (operator == null && operators.length > 0) {
              console.log(`Invalid operator ${row.operatorId}`)
            }
            let channelDesc
            switch (row.channel) {
              case 'sms':
                channelDesc = 'Sms'
                break
              case 'whatsapp':
                channelDesc = 'WhatsApp'
                break
              default:
                channelDesc = 'Telefono'
                break
            }
            let reasonDesc = 'Non specificato'
            if (row.reasonId != null) {
              const reason = contactReasons.find((cr) => cr.id === row.reasonId)
              if (reason) {
                reasonDesc = reason.label
              }
            }

            return (
              <TableRow key={row.timestamp}>
                <TableCell>
                  {operator == null ? (
                    <span>{opLabel}</span>
                  ) : (
                    <Link
                      to={`/operators/${row.operatorId}`}
                      component={RouterLink}>
                      {opLabel}
                    </Link>
                  )}
                </TableCell>
                <TableCell>{channelDesc}</TableCell>
                <TableCell>{reasonDesc}</TableCell>
                <TableCell
                  className={classes.columnPadding}
                  component="th"
                  scope="row">
                  {format(new Date(row.timestamp), 'PPPPpp', {locale: it})}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

ContactedOps.propTypes = {
  contactedOps: PropTypes.arrayOf(
    PropTypes.shape({
      operatorId: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      channel: PropTypes.string,
      reasonId: PropTypes.number,
    })
  ),
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      surname: PropTypes.string,
    })
  ),
  contactReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
}

export default ContactedOps
