import React from 'react'
import PropTypes from 'prop-types'
import {
  GoogleMap,
  Marker,
  useLoadScript,
  MarkerClusterer,
} from '@react-google-maps/api'
import {mapDefaultLongitude, mapDefaultLatitude} from '../../Config'

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY

function ExpoMap({operators = [], mapCenter, setMapInstance}) {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: API_KEY,
    language: 'IT-it',
  })

  const renderMap = () => {
    return (
      <GoogleMap
        options={{
          streetViewControl: false,
        }}
        id="circle-example"
        mapContainerStyle={{
          flex: 1,
          height: '100%',
          width: '100%',
        }}
        zoom={7}
        // streetView={false}
        // onCenterChanged={onCenterChanged}
        onLoad={setMapInstance}
        center={mapCenter}>
        <MarkerClusterer
          maxZoom={21}
          gridSize={30}
          options={{
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          }}>
          {(clusterer) => {
            return operators.map((operator) => {
              const {
                latitude: lat = mapDefaultLatitude,
                longitude: lng = mapDefaultLongitude,
                color,
                userId,
              } = operator
              const icon = {
                url: operator.isTracking
                  ? require(`../../assets/images/pins/operators/svg/${color}.svg`)
                  : require(`../../assets/images/pins/operators/svg/${color}-alpha.svg`),
              }
              return (
                <Marker
                  key={userId}
                  position={{lat, lng}}
                  clusterer={clusterer}
                  icon={icon}
                />
              )
            })
          }}
        </MarkerClusterer>
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <div>Loading</div>
}

ExpoMap.propTypes = {
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      isTracking: PropTypes.bool,
      name: PropTypes.string,
      surname: PropTypes.string,
      workHours: PropTypes.string,
      color: PropTypes.string.isRequired,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    })
  ).isRequired,
}

export default ExpoMap
