import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonPin from '../Ball'
import logo from '../../assets/images/logo_bucciarelli_ext.svg'
import footer from '../../assets/images/logo-pb-airbag-studio@2x.png'
import {format} from 'date-fns'
import {it} from 'date-fns/locale'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Button from '@material-ui/core/Button'
import useInterval from '../../Hooks/Interval'
// import Typography from '@material-ui/core/Typography'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // toolbar: theme.mixins.toolbar,
  logoContainer: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    // backgroundColor: 'grey',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '200px',
  },
  clockContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // backgroundImage:
    //   'linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #4980B2 100%)',
  },
  clock: {
    display: 'block',
    // fontFamily: "Roboto";
    fontWeight: 300,
    fontSize: '54px',
  },
  calendar: {
    display: 'block',
    // fontFamily: "Roboto";
    fontWeight: 300,
    fontSize: '20px',
  },
  lastUpdateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage:
      'linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #4980B2 100%)',
    backgroundColor: '#4980B2',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  lastUpdate: {
    display: 'block',
    fontWeight: 300,
    fontSize: '11px',
    color: '#ffffff',
  },
  exitButton: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  pin: {
    fontSize: '3.5em',
    lineHeight: '36px',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
    alignItems: 'stretch',
  },
  footerLogoContainer: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // backgroundColor: 'grey',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerLogo: {
    width: '206px',
    height: '22px',
  },
  version: {
    marginRight: theme.spacing(3),
    fontSize: '8px',
    // alignSelf: 'right',
    // backgroundColor: 'red',
    textAlign: 'right',
  },
}))

function ExpoDrawer(props) {
  const classes = useStyles()
  const {operators: propOperators, lastUpdate, onLogout} = props
  const [date, setDate] = useState(new Date())
  const [operators, setOperators] = useState([])
  // console.log('rendering drawer', date)
  // console.log('lastUpdate', lastUpdate)
  // console.log('operators', operators)

  useInterval(() => {
    setDate(new Date())
  }, 60 * 1000)

  useEffect(() => {
    // console.log('refreshing operators', propOperators)
    if (propOperators && propOperators.length > 0) {
      // console.log('ok, can refresh')
      const orderedOperators = propOperators.slice().sort((a, b) => {
        const aComplete = `${a.surname} ${a.name}`
        const bComplete = `${b.surname} ${b.name}`
        return aComplete.localeCompare(bComplete)
      })
      setOperators(orderedOperators)
    } else {
      // console.log('no, cannot refresh')
    }
  }, [propOperators, setOperators])

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left">
      {/* <div className={classes.toolbar} /> */}
      <div className={classes.logoContainer}>
        <img src={logo} className={classes.logo} alt="logo" />
      </div>
      <Divider />
      <div className={classes.clockContainer}>
        <span className={classes.clock}>{format(date, 'HH:mm')}</span>
        <span className={classes.calendar}>
          {format(date, 'eeee d MMMM', {locale: it})}
        </span>
      </div>
      <div className={classes.lastUpdateContainer}>
        <span className={classes.lastUpdate}>ULTIMO AGGIORNAMENTO</span>
        <span className={classes.lastUpdate}>
          <b>{lastUpdate ? format(lastUpdate, 'HH:mm') : '-'}</b>
        </span>
      </div>
      <List>
        {operators.map((operator) => (
          <ListItem button key={operator.userId} dense>
            <ListItemIcon>
              <PersonPin
                color={operator.color}
                fontSize="inherit"
                className={classes.pin}
                untracked={!operator.isTracking}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${operator.surname} ${operator.name}`}
              primaryTypographyProps={
                operator.isTracking ? {} : {style: {fontStyle: 'italic'}}
              }
            />
          </ListItem>
        ))}
      </List>
      <Button color="inherit" className={classes.exitButton} onClick={onLogout}>
        <ExitToAppIcon className={classes.leftIcon} />
        Esci
      </Button>
      <div className={classes.footerContainer}>
        <div className={classes.footerLogoContainer}>
          <a
            href="https://www.airbagstudio.it"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={footer}
              className={classes.footerLogo}
              alt="Airbag Studio logo"
            />
          </a>
        </div>
        <p className={classes.version}>
          Versione {process.env.REACT_APP_VERSION}
        </p>
      </div>
    </Drawer>
  )
}

ExpoDrawer.propTypes = {
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    })
  ).isRequired,
  lastUpdate: PropTypes.instanceOf(Date),
  onLogout: PropTypes.func.isRequired,
}

export default ExpoDrawer
