import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

const Title = styled.h3`
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
`

const Description = styled.p`
  color: #000000;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
`

CampaignEmptyState.propTypes = {
  // onNewCampaign: PropTypes.func.isRequired,
}

function CampaignEmptyState() {
  return (
    <div className="mb-5">
      <Title>
        Qui appariranno le statistiche complessive delle tue campagne.
      </Title>
      <Description>
        Non hai creato ancora nessuna campagna push.
        <br />
        Crea la tua prima campagna.
      </Description>
    </div>
  )
}

export default React.memo(CampaignEmptyState)
