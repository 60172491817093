import React, {useState, useContext, useEffect} from 'react'
// import queryString from 'query-string'
import {format, addHours, roundToNearestMinutes} from 'date-fns'
import {it} from 'date-fns/locale'
import NewCampaign from './NewCampaign'
import useInput, {useArrayInput} from '../../CustomHooks/useInput'
import {CampaignsContext} from '../../Contexts/CampaignsContext'
import useCampaigns from '../../CustomHooks/useCampaigns'
import {TopicsContext} from '../../Contexts/TopicsContext'
import useTopics from '../../CustomHooks/useTopics'

function NewCampaignsWrapper({onSuccess}) {
  const [campaignState] = useContext(CampaignsContext)
  const [topicsState] = useContext(TopicsContext)
  const {postRequest = {}} = campaignState
  const {topicsStats: topics} = topicsState
  // const topics = [] // temporary without topics
  const {createCampaign, resetPostRequest} = useCampaigns()
  const {fetchTopicStats} = useTopics()
  const {value: name, bind: bindName} = useInput(
    `Campagna ${format(new Date(), 'Pp', {locale: it})}`
  )
  const {value: title, bind: bindTitle} = useInput('')
  const {value: text, bind: bindText} = useInput('')
  const {value: url, bind: bindUrl} = useInput('')
  const {value: imageUrl, bind: bindImageUrl} = useInput('')
  const {
    value: os,
    setValue: setOs,
    bind: bindOs,
  } = useArrayInput(['ios', 'android'])
  const {value: topic, setValue: setTopic, bind: bindTopic} = useInput('')
  const {value: startDate, setValue: setStartDate} = useInput('')
  const [isImmediate, setIsImmediate] = useState(true)

  function handleChangeImmediate(value) {
    if (value === 'false') {
      setStartDate(
        roundToNearestMinutes(addHours(new Date(), 1), {
          nearestTo: 15,
        })
      )
      setIsImmediate(false)
    } else {
      setIsImmediate(true)
      setStartDate(null)
    }
  }

  function validate() {
    return !!name && !!text && !!os && os.length > 0
  }
  useEffect(() => {
    async function fetchAll() {
      await fetchTopicStats()
    }
    fetchAll()
  }, [fetchTopicStats])
  useEffect(() => {
    if (postRequest.success) {
      // const {id} = postRequest
      resetPostRequest()
      // history.push(`/campaigns/${id}`)
      onSuccess()
    }
  }, [postRequest.success, resetPostRequest, onSuccess])

  async function startNewCampaign() {
    let target = 'all'
    if (topic) {
      target = 'topic'
    } else if (os.length === 1) {
      target = os[0]
    }
    const values = {
      name,
      title,
      body: text,
      url,
      imageUrl,
      target,
      topic,
      isScheduled: false,
      selectedDate: startDate,
    }
    await createCampaign(values)
  }

  const commonProps = {
    topics,
    name,
    bindName,
    title,
    bindTitle,
    text,
    bindText,
    url,
    bindUrl,
    imageUrl,
    bindImageUrl,
    os,
    setOs,
    bindOs,
    topic,
    setTopic,
    bindTopic,
    startDate,
    setStartDate,
    isImmediate,
    onChangeImmediate: handleChangeImmediate,
    onValidate: validate,
    onSubmitCampaign: startNewCampaign,
    isSubmitting: postRequest.sending,
    hasError: postRequest.error,
    success: postRequest.success,
  }

  return <NewCampaign {...commonProps} />
}

export default NewCampaignsWrapper
