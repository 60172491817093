import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
// import clsx from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minWidth: 650,
  },
}))

function CustomersList({rows, onCustomerDetail}) {
  const classes = useStyles()

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell>Cliente</TableCell>
          <TableCell>Indirizzo</TableCell>
          <TableCell>Latitudine</TableCell>
          <TableCell>Longitudine</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows
          .filter(({disabled = false}) => !disabled)
          .map((row) => (
            <TableRow
              hover
              key={row.customerId}
              onClick={() =>
                onCustomerDetail(row.customerHashKey, row.customerId)
              }>
              <TableCell>
                <b>{row.name}</b>
              </TableCell>
              <TableCell>{row.address}</TableCell>
              <TableCell>{row.latitude}</TableCell>
              <TableCell>{row.longitude}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

CustomersList.propTypes = {
  onCustomerDetail: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      customerId: PropTypes.string.isRequired,
      customerHashKey: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default CustomersList
