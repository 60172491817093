import React from 'react'
import PropTypes from 'prop-types'
// import * as Icons from 'react-feather'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faApple, faAndroid} from '@fortawesome/free-brands-svg-icons'
import {Form} from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
// import ModalInfo from './NewCampaignModalInfo'
import StepWrapper from './NewCampaignStepWrapper'
// import {Link} from 'react-router-dom'
import NewCampaignKoAlert from './NewCampaignKoAlert'
import PreviewAndroid from './NewCampaignPreviewAndroid'
import PreviewIos from './NewCampaignPreviewIos'
// import Breadcrumb from '../DynamicBreadcrumb'

NewCampaignsComponent.propTypes = {
  onSubmitCampaign: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  success: PropTypes.bool,
  hasError: PropTypes.bool,
}

function NewCampaignsComponent(props) {
  const {
    // onSubmitCampaign,
    // isSubmitting,
    // success,
    // hasError,
    title,
    text,
    imageUrl,
    bindName,
    hasError,
  } = props
  return (
    <React.Fragment>
      {!!hasError && <NewCampaignKoAlert />}
      <div className="row">
        <div className="col-md-7 mb-5">
          {/* <Breadcrumb /> */}
          <h2 className="pt-0 pb-2">Nuova campagna</h2>
          <small className="form-text text-muted mb-4">
            Avvia una nuova campagna di notifiche push verso il tuo pubblico
            compilando questo modulo.
            {/* <ModalInfo /> */}
          </small>
          <Form.Group controlId="campaignName">
            <Form.Label className="formLabel">Nome della campagna</Form.Label>
            <Form.Control
              className="form-control-lg"
              type="text"
              placeholder="Inserisci il nome della campagna"
              {...bindName}
            />
          </Form.Group>

          <StepWrapper {...props} />
        </div>
        <div className="col-md-4 offset-md-1 previewPane position-sticky">
          {/* <h1 className="h3 pt-5 mt-4 pb-2">Anteprima</h1> */}
          <h1 className="h3 pb-2">Anteprima</h1>
          <div className="d-flex">
            <FontAwesomeIcon icon={faApple} className="os-icon mr-3" />
            <PreviewIos text={text} imageUrl={imageUrl} />
          </div>
          <div className="d-flex mt-5">
            <FontAwesomeIcon icon={faAndroid} className="os-icon mr-3" />
            <PreviewAndroid title={title} text={text} imageUrl={imageUrl} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewCampaignsComponent
