import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import OperatorsList from './OperatorsList'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100vh',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  title: {
    paddingLeft: theme.spacing(3),
    // flex: 1,
    // marginTop: '3px',
    // backgroundColor: 'red',
    // position: 'relative',
  },
}))

function Operators({rows, onUserDetail}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Operatori disabilitati
      </Typography>
      <OperatorsList rows={rows} onUserDetail={onUserDetail} />
    </div>
  )
}

export default Operators
