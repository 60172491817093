import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LabeledCell from '../DetailLabeledCell'

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft: theme.spacing(4),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  gridContainerTitle: {
    // backgroundColor: 'green',
    // borderBottom: '2px solid #C4CDD5',
    // marginBottom: theme.spacing(3),
    // paddingBottom: theme.spacing(1),
  },
  gridItem: {
    // backgroundColor: 'red',
    marginBottom: theme.spacing(2),
  },
}))

function DetailInfoApp(props) {
  const classes = useStyles()
  const {os = '-', osVersion = '', version = '-'} = props
  let osVersionJoined
  switch (os) {
    case 'android':
      osVersionJoined = `Android ${osVersion}`
      break
    case 'ios':
      osVersionJoined = `iOS ${osVersion}`
      break
    default:
      osVersionJoined = '-'
      break
  }
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainerTitle}>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Versione" description={version} />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Sistema Operativo"
            description={osVersionJoined}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>
      </Grid>
    </div>
  )
}

DetailInfoApp.propTypes = {
  os: PropTypes.string,
  osVersion: PropTypes.string,
  version: PropTypes.string,
}

export default DetailInfoApp
