import React, {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
// import {useNavigate} from 'react-router-dom'
import NavBar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Campaigns from './Components/Campaigns'
import {CampaignsProvider} from './Contexts/CampaignsContext'
import {TopicsProvider} from './Contexts/TopicsContext'
import ErrorSnackbar from '../Components/CustomSnackbar/OnlyErrorSnackbar'
import {useAuthenticator} from '@aws-amplify/ui-react'
import './custom.scss'
import {CampaignsContext} from './Contexts/CampaignsContext'
// import useCampaigns from './CustomHooks/useCampaigns'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
    // marginTop: '3px',
    // backgroundColor: 'red',
    // position: 'relative',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(-50),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
}))

function Pushdeck() {
  const classes = useStyles()
  const [state] = useContext(CampaignsContext)

  // const [fetching, setFetching] = useState(false)
  // const [responseData, setResponseData] = useState()
  // const [requestError, setRequestError] = useState()
  const {signOut} = useAuthenticator((context) => [context.user])
  return (
    <div className={classes.root}>
      <header>
        <NavBar
          isAdmin
          loading={state?.listRequest?.fetching}
          onLogout={signOut}
        />
      </header>
      <main className={classes.main}>
        <Campaigns />
      </main>
      <footer>
        <Footer />
      </footer>
      <ErrorSnackbar
        response={state?.campaigns}
        error={state?.listRequest?.error}
        errorMessage={'Impossibile caricare la lista delle campagne push'}
      />
    </div>
  )
}

export default function PushdeckWrapper() {
  return (
    <CampaignsProvider>
      <TopicsProvider>
        <Pushdeck />
      </TopicsProvider>
    </CampaignsProvider>
  )
}
