import React, {memo} from 'react'
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card'
import Stars from '../Stars'
import SuccessChart from '../Charts/CampaignsSuccessLine'
// import {format, isValid} from 'date-fns'
// import {it} from 'date-fns/locale'

const styles = {
  card: {
    width: '300px',
    minHeight: '200px',
    borderRadius: '0.3rem',
  },
  cardChart: {
    flex: '2 0',
    borderRadius: '0.3rem',
    // height: '200px',
  },
  title: {
    color: '#1F2126',
    fontSize: '21px',
    fontWeight: '600',
    letterSpacing: '0.61px',
    lineHeight: '25px',
    textAlign: 'center',
  },
  titleChart: {
    color: '#1F2126',
    fontSize: '21px',
    fontWeight: '600',
    letterSpacing: '0.61px',
    lineHeight: '25px',
  },
  subtitle: {
    color: 'rgba(60,60,67,0.6)',
    fontSize: '12px',
    letterSpacing: '0.3px',
    lineHeight: '25px',
  },
  total: {
    marginTop: '60px',
    color: '#1F2126',
    fontSize: '79px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '58px',
  },
  avg: {
    marginTop: '20px',
    color: '#1F2126',
    fontSize: '52px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '58px',
  },
  pushLabel: {
    color: 'rgba(60,60,67,0.6)',
    fontSize: '11px',
    letterSpacing: '0.07px',
    lineHeight: '13px',
  },
  pushValue: {
    color: '#1F2126',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
  },
}

function CampaignsStats({stats}) {
  const {
    totalCampaigns,
    totalSent,
    totalOpened,
    avgSuccessRate,
    successSeries,
  } = stats
  // const firstDate =
  //   successSeries.length > 0 ? new Date(successSeries[0].startDate) : null
  // const firstDateDesc = isValid(firstDate)
  //   ? format(firstDate, "'Dal 'PPP' alle 'pp", {locale: it})
  //   : null
  return (
    <CardDeck>
      <Card style={styles.card}>
        <Card.Body className="d-flex flex-column align-items-center">
          <Card.Text style={styles.title}>Campagne completate</Card.Text>
          <Card.Text style={styles.total}>{totalCampaigns}</Card.Text>
        </Card.Body>
      </Card>

      <Card style={styles.card}>
        <Card.Body className="d-flex flex-column align-items-center">
          <Card.Text style={styles.title}>Successo medio</Card.Text>
          <Card.Text style={styles.avg}>{avgSuccessRate}%</Card.Text>
          <Stars rate={avgSuccessRate} />
          <div className="d-flex mt-3">
            <div className="d-flex flex-column align-items-center mr-3">
              <span style={styles.pushLabel}>APERTE</span>
              <span style={styles.pushValue}>{totalOpened}</span>
            </div>
            <div className="d-flex flex-column align-items-center ml-3">
              <span style={styles.pushLabel}>INVIATE</span>
              <span style={styles.pushValue}>{totalSent}</span>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card style={styles.cardChart}>
        <Card.Body className="d-flex flex-column">
          <Card.Text style={styles.titleChart} className="mb-1">
            Andamento dell'indice di successo
          </Card.Text>
          <Card.Text style={styles.subtitle} className="mt-0">
            Ultime trenta campagne
          </Card.Text>
          <div style={{height: '180px'}}>
            <SuccessChart data={successSeries} />
          </div>
        </Card.Body>
      </Card>
    </CardDeck>
  )
}

export default memo(CampaignsStats)
