import React, {memo} from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from 'recharts'
import Stars from '../Stars'
import {format, isValid} from 'date-fns'
import {it} from 'date-fns/locale'

const styles = {
  customTooltip: {
    width: '200px',
    margin: '0 20px',
    lineHeight: '24px',
    border: '2px solid #f5f5f5',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '10px',
  },
  label: {
    fontSize: '12px',
    margin: 0,
    color: '#666',
    fontWeight: '700',
  },
  desc: {
    fontSize: '12px',
    margin: 0,
    color: '#999',
  },
  intro: {
    fontSize: '12px',
    borderTop: '1px solid #f5f5f5',
    margin: 0,
  },
  normal: {
    fontSize: '12px',
    margin: 0,
  },
}

function CustomTooltip({payload, active}) {
  if (
    active &&
    payload &&
    payload.length > 0 &&
    payload[0] &&
    payload[0].payload
  ) {
    const {payload: data = {}} = payload[0]
    const {progressive, startDate, name, successRate = 0} = data
    const date = isValid(new Date(startDate))
      ? format(new Date(startDate), "P' 'p", {locale: it})
      : '-'

    return (
      <div style={styles.customTooltip}>
        <div className="m0 d-flex justify-content-between align-items-center">
          <p style={styles.label}>{`# ${progressive}`}</p>
          <Stars rate={successRate} />
        </div>
        <p style={styles.intro}>{`${name}`}</p>
        <p style={styles.normal}>{`Successo: ${successRate}%`}</p>
        <p style={styles.desc}>{`${date}`}</p>
        {/* <p style={styles.desc}>{`Successo: ${successRate}%`}</p> */}
      </div>
    )
  }
  return null
}

function CampaignSuccessLineChart(props) {
  const {data = []} = props
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 5,
          bottom: 5,
        }}>
        <CartesianGrid vertical={false} unit="%" strokeDasharray="3 3" />
        <XAxis dataKey="progressive" />
        <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="successRate"
          stroke="#05386B"
          name="Indice di successo"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default memo(CampaignSuccessLineChart)
