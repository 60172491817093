import React, {useContext, useEffect, useState, useMemo} from 'react'
import Campaigns from './Campaigns'
// import {useHistory} from 'react-router-dom'
import {useDebounce} from 'use-debounce'
import useCampaigns from '../../CustomHooks/useCampaigns'
import {CampaignsContext} from '../../Contexts/CampaignsContext'
import useTopics from '../../CustomHooks/useTopics'
import {TopicsContext} from '../../Contexts/TopicsContext'
import Loader from '../Loader'
import ModalNewCampaign from '../ModalNewCampaign'

function calcSuccessRate(sent, opened) {
  return sent === 0 ? 0 : Math.round((opened / sent) * 100)
}

function CampaignsWrapper() {
  const [state] = useContext(CampaignsContext)
  const [topicsState] = useContext(TopicsContext)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  // const history = useHistory()
  const {getCampaigns} = useCampaigns()
  const {fetchTopicStats} = useTopics()
  const {topicsStats: topics} = topicsState
  const [openNewCampaign, setOpenNewCampaign] = React.useState(false)
  const onNewCampaign = () => {
    // history.push('/campaigns/new')
    setOpenNewCampaign(true)
  }
  useEffect(() => {
    async function fetchAll() {
      await getCampaigns()
      await fetchTopicStats()
    }
    fetchAll()
  }, [fetchTopicStats, getCampaigns])

  const campaigns = useMemo(() => state.campaigns || [], [state.campaigns])
  const campaignsForStats = campaigns.filter(
    (campaign) => campaign.status === 'completed'
  )
  const totalCampaigns = campaignsForStats.length
  const totalSent = campaignsForStats.reduce(
    (acc, elem) => acc + elem.totalSent,
    0
  )
  const totalOpened = campaignsForStats.reduce(
    (acc, elem) => acc + elem.opened,
    0
  )
  const avgSuccessRate = calcSuccessRate(totalSent, totalOpened)
  const successSeries = campaignsForStats
    .map((campaign) => ({
      progressive: campaign.progressive,
      name: campaign.name,
      startDate: campaign.startDate,
      successRate: calcSuccessRate(campaign.totalSent, campaign.opened),
    }))
    .slice(0, 30)
    .reverse()

  const stats = {
    totalCampaigns,
    totalSent,
    totalOpened,
    avgSuccessRate,
    successSeries,
  }

  const filteredCampaigns = useMemo(() => {
    if (debouncedSearch) {
      const upperSearch = debouncedSearch.toUpperCase()
      return campaigns.filter(
        ({body = '', title = '', name = ''}) =>
          (body || '').toUpperCase().indexOf(upperSearch) !== -1 ||
          (title || '').toUpperCase().indexOf(upperSearch) !== -1 ||
          (name || '').toUpperCase().indexOf(upperSearch) !== -1
      )
    }
    return campaigns
  }, [campaigns, debouncedSearch])

  if (state.listRequest.fetching) {
    return (
      <div
        className="row d-flex justify-content-center align-items-center"
        style={{height: '100vh'}}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <Campaigns
        toNewCampaign={onNewCampaign}
        fetching={state.listRequest.fetching}
        data={filteredCampaigns}
        // data={[]}
        handleSearch={setSearch}
        stats={stats}
        topics={topics}
      />
      <ModalNewCampaign open={openNewCampaign} setOpen={setOpenNewCampaign} />
    </>
  )
}

export default CampaignsWrapper
