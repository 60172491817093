import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
// import clsx from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {format} from 'date-fns'
import {it} from 'date-fns/locale'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minWidth: 650,
  },
}))

function CustomersList({rows, onCustomerDetail}) {
  const classes = useStyles()
  const [customers, setCustomers] = React.useState(rows)

  React.useEffect(() => {
    let filtered = rows.filter(({enabled = false}) => enabled)
    filtered.sort((a, b) => a.companyName.localeCompare(b.companyName))
    setCustomers(filtered)
  }, [rows, setCustomers])

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell>Azienda</TableCell>
          <TableCell>Referente</TableCell>
          <TableCell>Contatti</TableCell>
          <TableCell>Data Iscrizione</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customers.map((row) => (
          <TableRow
            hover
            key={row.userId}
            onClick={() => onCustomerDetail(row.userId)}>
            <TableCell>
              <b>{row.companyName}</b>
              <br />
              <address>{row.companyAddress}</address>
            </TableCell>
            <TableCell>
              {row.firstName} {row.lastName}
            </TableCell>
            <TableCell>
              <b>Telefono:</b> {row.phoneNumber}
              <br />
              <b>Email:</b> {row.email}
            </TableCell>
            <TableCell>
              {format(new Date(row.createdAt), 'P', {locale: it})}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

CustomersList.propTypes = {
  onCustomerDetail: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      companyAddress: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      email: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default CustomersList
