import React, {useState, useEffect} from 'react'
import {apiBaseUrl, normalApiTimeout} from '../Config'
import axios from 'axios'
import {Auth} from 'aws-amplify'

const CustomerTypologiesCacheContext = React.createContext([[], () => {}])

const CustomerTypologiesCacheProvider = (props) => {
  const [state, setState] = useState([])

  useEffect(() => {
    async function retrieveCustomerTypologies() {
      const session = await Auth.currentSession()
      const token = session.getAccessToken().getJwtToken()
      if (token == null) {
        console.error('contact reasons cache, not authenticated')
        return
      }
      const result = await axios({
        url: `${apiBaseUrl}/gbp/customer-typologies`,
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`},
        timeout: normalApiTimeout,
      })
      // console.log(
      //   'Customer typologies cache',
      //   result && result.data && result.data.success ? result.data.data : result
      // )
      setState(
        result && result.data && result.data.success ? result.data.data : []
      )
    }
    retrieveCustomerTypologies()
  }, [])

  return (
    <CustomerTypologiesCacheContext.Provider value={[state, setState]}>
      {props.children}
    </CustomerTypologiesCacheContext.Provider>
  )
}

export {CustomerTypologiesCacheContext, CustomerTypologiesCacheProvider}
