import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import OperatorsList from './OperatorsList'
import OperatorsDisabled from './OperatorsDisabled'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import {useNavigate} from 'react-router-dom'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout, UserClass} from '../../Config'
import useAxios from 'axios-hooks'
import {useAuthenticator} from '@aws-amplify/ui-react'
import {
  workHourToString,
  userClassToString,
} from '../../utilities/user-transformers'
import {getTrackingStatus} from '../../utilities/tracking-utils'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'

// avoid browser cache
let now = Date.now()

function createData(user) {
  const {
    userId,
    name,
    surname,
    color,
    workHour,
    class: userClass,
    lastPosition,
    lastState,
    disabled,
    isGbPeopleExposable = false,
  } = user
  const workHoursDesc = workHourToString(workHour)
  const userClassDesc = userClassToString(userClass)
  const trackingState = getTrackingStatus(lastState, lastPosition)
  return {
    userId,
    trackingState,
    name,
    surname,
    workHours: workHoursDesc,
    color,
    userClass: userClassDesc,
    isGbPeopleExposable: isGbPeopleExposable && !disabled,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
    // marginTop: '3px',
    // backgroundColor: 'red',
    // position: 'relative',
  },
  operatorsDisabled: {
    marginTop: theme.spacing(10),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

function Operators() {
  const classes = useStyles()
  const navigate = useNavigate()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  const [{data: responseData, loading: fetching, error: requestError}] =
    useAxios({
      url: `${apiBaseUrl}/users?state=true&fakeTimestamp=${now}`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    })

  React.useEffect(() => {
    now = Date.now()
  }, [])

  let enabledRows = []
  let disabledRows = []
  // let dashboardUsersRows = []
  if (responseData) {
    // dashboardUsersRows = responseData.filter((user) =>
    //   [UserClass.EXHIBITOR, UserClass.ADMINISTRATOR].includes(user.class)
    // )
    const mobileUsers = responseData.filter(
      (user) =>
        ![UserClass.EXHIBITOR, UserClass.ADMINISTRATOR].includes(user.class)
    )
    enabledRows = mobileUsers.filter((user) => !user.disabled).map(createData)
    enabledRows.sort((a, b) => a.surname.localeCompare(b.surname))
    disabledRows = mobileUsers.filter((user) => user.disabled).map(createData)
    disabledRows.sort((a, b) => a.surname.localeCompare(b.surname))
  }
  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <OperatorsList
          rows={enabledRows}
          onUserDetail={(userId) => navigate(`/operators/${userId}`)}
        />
        {disabledRows && disabledRows.length > 0 && (
          <div className={classes.operatorsDisabled}>
            <OperatorsDisabled
              rows={disabledRows}
              onUserDetail={(userId) => navigate(`/operators/${userId}`)}
            />
          </div>
        )}
        <Fab
          color="primary"
          aria-label="Aggiungi operatore"
          onClick={() => navigate('/operators/new')}
          className={classes.fab}>
          <AddIcon />
        </Fab>
      </main>
      <footer>
        <Footer />
      </footer>
      <ErrorSnackbar
        response={responseData}
        error={requestError}
        errorMessage={'Impossibile caricare la lista degli utenti'}
      />
    </div>
  )
}

export default Operators
