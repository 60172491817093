import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
// import clsx from 'clsx'
import {Colors} from '../../Config'
// import CircleIcon from '@material-ui/icons/Lens'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import blue from '@material-ui/core/colors/blue'
import brown from '@material-ui/core/colors/brown'
import cyan from '@material-ui/core/colors/cyan'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import pink from '@material-ui/core/colors/pink'
import purple from '@material-ui/core/colors/purple'
import red from '@material-ui/core/colors/red'
import teal from '@material-ui/core/colors/teal'
import yellow from '@material-ui/core/colors/yellow'
import grey from '@material-ui/core/colors/grey'

export const getRawBallStyles = () => ({
  blue: {
    color: blue[500],
  },
  blueDisabled: {
    color: blue[200],
  },
  blueBg: {
    backgroundColor: blue[500],
  },
  brown: {
    color: brown[500],
  },
  brownDisabled: {
    color: brown[200],
  },
  brownBg: {
    backgroundColor: brown[500],
  },
  cyan: {
    color: cyan[300],
  },
  cyanDisabled: {
    color: cyan[100],
  },
  cyanBg: {
    backgroundColor: cyan[300],
  },
  green: {
    color: green[500],
  },
  greenDisabled: {
    color: green[200],
  },
  greenBg: {
    backgroundColor: green[500],
  },
  orange: {
    color: orange[800],
  },
  orangeDisabled: {
    color: orange[500],
  },
  orangeBg: {
    backgroundColor: orange[800],
  },
  pink: {
    color: pink[300],
  },
  pinkDisabled: {
    color: pink[100],
  },
  pinkBg: {
    backgroundColor: pink[300],
  },
  purple: {
    color: purple[500],
  },
  purpleDisabled: {
    color: purple[200],
  },
  purpleBg: {
    backgroundColor: purple[500],
  },
  red: {
    color: red[500],
  },
  redDisabled: {
    color: red[200],
  },
  redBg: {
    backgroundColor: red[500],
  },
  teal: {
    color: teal[500],
  },
  tealDisabled: {
    color: teal[200],
  },
  tealBg: {
    backgroundColor: teal[500],
  },
  yellow: {
    color: yellow[700],
  },
  yellowDisabled: {
    color: yellow[500],
  },
  yellowBg: {
    backgroundColor: yellow[700],
  },
  grey: {
    color: grey[500],
  },
  greyDisabled: {
    color: grey[200],
  },
  greyBg: {
    backgroundColor: grey[500],
  },
})

export const useBallStyles = makeStyles(getRawBallStyles)

function OperatorPin({
  color,
  className,
  fontSize = 'large',
  untracked = false,
}) {
  const classes = useBallStyles()
  return (
    <span className={className || null}>
      <PersonPinCircleIcon
        className={classes[`${color}${untracked ? 'Disabled' : ''}` || 'grey']}
        fontSize={fontSize}
      />
    </span>
  )
}

OperatorPin.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors)).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
  untracked: PropTypes.bool,
}

export default OperatorPin
