import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import useAxios from 'axios-hooks'
import {useNavigate} from 'react-router-dom'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import {useAuthenticator} from '@aws-amplify/ui-react'
import OperatorNew from './OperatorNew'
import SaveSnackbar from '../CustomSnackbar/SaveSnackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
  },
}))

function OperatorNewPage(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()

  const [
    {data: saveResponse, loading: savingUser, error: errorSaving},
    executeSave,
  ] = useAxios(
    {
      url: `${apiBaseUrl}/users`,
      method: 'POST',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    },
    {manual: true}
  )

  let userData = {
    disabled: false,
    color: 'blue',
    startHour: 9,
    startMinute: 0,
    endHour: 18,
    endMinute: 0,
  }

  let errors = {}
  if (errorSaving) {
    console.error({errorSaving})
    const {response = {}} = errorSaving
    const {status, data = {}} = response
    const {code} = data
    // console.log({status, message})
    if (status === 400 && code === 'UsernameExistsException') {
      errors.email = 'Email già utilizzata da un altro utente'
    }
  }

  const onSave = async (flatData, formikBag) => {
    // console.log('flatData', flatData)
    // console.log('formikBag', formikBag)
    const toPaddedString = (n) => (n < 10 ? `0${n}` : `${n}`)
    const {
      class: c,
      userId: uid,
      startHour,
      startMinute,
      endHour,
      endMinute,
      ...data
    } = flatData
    try {
      await executeSave({
        data: {
          ...data,
          workHour: {
            startHour: toPaddedString(startHour),
            startMinute: toPaddedString(startMinute),
            endHour: toPaddedString(endHour),
            endMinute: toPaddedString(endMinute),
          },
        },
      })
    } catch (error) {
      console.error({error})
    }
    formikBag.setSubmitting(false)
  }
  const fetching = savingUser

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <OperatorNew
          savingUser={savingUser}
          userData={userData}
          onSave={onSave}
          errors={errors}
          onBack={() => navigate('/operators')}
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <SaveSnackbar
        response={saveResponse}
        error={errorSaving}
        errorMessage={errors.email || 'Errore durante il salvataggio'}
      />
    </div>
  )
}

OperatorNewPage.propTypes = {}

export default OperatorNewPage
