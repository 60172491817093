import React, {useEffect, useContext, useState} from 'react'
import useTopics from '../../CustomHooks/useTopics'
import {TopicsContext} from '../../Contexts/TopicsContext'
import TopicBadge from './TopicBadge'

export const TopicsColors = [
  // '#000',
  // '#0088FE',
  // '#00C49F',
  // '#FFBB28',
  // '#FF002A',
  // '#00FFD4',
  // '#FF5D00',
  // '#FF00A2',
  // '#5D00FF',
  // '#A2FF00',
  // '#999',

  // divergente 1
  // '#F64C72',
  // '#05386B',
  // '#3F4E84',
  // '#67669C',
  // '#8D7FB5',
  // '#B399CE',
  // '#D9B5E6',
  // '#FFD2FF',
  // '#FFBDEF',
  // '#FFA8DD',
  // '#FF92C6',
  // '#FF7BAD',
  // '#FC6490',

  // divergente 2
  '#9E0142',
  '#D53E4F',
  '#F46D43',
  '#FDAE61',
  '#FEE08B',
  '#FFFFBF',
  '#E6F598',
  '#ABDDA4',
  '#66C2A5',
  '#3288BD',
  '#5E4FA2',

  // divergente 3
  // '#00876C',
  // '#3D9A6F',
  // '#64AC72',
  // '#89BE75',
  // '#AFCF7A',
  // '#D6DF81',
  // '#FFEE8C',
  // '#FDD374',
  // '#FAB661',
  // '#F59955',
  // '#ED7C4F',
  // '#E35D4F',
  // '#D43D51',
]

export default function TopicBadgeWrapper({topics, ...props}) {
  // const {topics} = props
  const [state] = useContext(TopicsContext)
  const {fetchTopicStats} = useTopics()
  const {topicsStats = [], topicsRequest = {}} = state
  const [requestDone, setRequestDone] = useState(false)
  useEffect(() => {
    async function fetchTopics() {
      await fetchTopicStats()
    }
    if (!topics && !requestDone && topicsStats.length === 0) {
      if (!topicsRequest.fetching) {
        fetchTopics()
      }
      setRequestDone(true)
    }
  }, [
    topics,
    fetchTopicStats,
    requestDone,
    topicsRequest.fetching,
    topicsStats.length,
  ])

  return <TopicBadge topics={topics || topicsStats} {...props} />
}
