import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {Card, Image} from 'react-bootstrap'
import ImagePreview from '../ImagePreview'

IosPreview.propTypes = {
  text: PropTypes.string.isRequired,
}

const BORDER_RADIUS = '12px'
const styles = {
  card: {
    width: '360px',
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    // padding: '5px 9px',
  },
  header: {
    borderTopRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    borderBottomWidth: 0,
    backgroundColor: '#e7e7e9',
    padding: '5px 9px',
  },
  appName: {
    opacity: 0.9,
    color: '#9B9B9B',
    // fontFamily: 'SFUIDisplay-Regular',
    fontSize: '13px',
    letterSpacing: 0,
    // lineHeight: '15px',
  },
  body: {
    borderBottomRightRadius: BORDER_RADIUS,
    borderBottomLeftRadius: BORDER_RADIUS,
    backgroundColor: '#f2f2f4',
    minHeight: '60px',
  },
  text: {
    fontSize: '15px',
    letterSpacing: 0,
    lineHeight: '18px',
  },
  logo: {
    backgroundColor: '#fff',
    padding: '2px',
  },
}

function IosPreview({text, imageUrl}) {
  return (
    <Card style={styles.card}>
      <Card.Header className="d-flex align-items-center" style={styles.header}>
        {/* <Image
          src="../imgs/pushdeck-logo-solo.svg"
          alt="App logo"
          rounded
          className="mr-1"
          style={styles.logo}
        /> */}
        <span style={styles.appName}>GBPEOPLE</span>
      </Card.Header>
      <Card.Body style={styles.body} className="pt-2">
        <ImagePreview imageUrl={imageUrl} side="right" size={60} />
        <Card.Text style={styles.text}>{text}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default memo(IosPreview)
