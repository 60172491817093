import {
  UserClass,
  // Colors
} from '../Config'

export function workHourToString(workHours) {
  if (workHours == null) {
    return ''
  }
  if (typeof workHours === 'string') {
    return workHours
  }
  const {startHour, endHour, startMinute, endMinute} = workHours
  return `${startHour}:${startMinute}-${endHour}:${endMinute}`
}

export function userClassToString(userClass) {
  switch (userClass) {
    case UserClass.ADMINISTRATOR:
      return 'Amministratore'
    case UserClass.EXHIBITOR:
      return 'Espositore'
    case UserClass.OPERATOR:
      return 'Operatore (vecchia utenza)'
    case UserClass.MOBILE:
      return 'Operatore'
    default:
      return 'sconosciuto'
  }
}

export function colorsToValueLabelPair() {
  return [
    {value: 'blue', label: 'Blu'},
    {value: 'brown', label: 'Marrone'},
    {value: 'cyan', label: 'Celeste'},
    {value: 'green', label: 'Verde'},
    {value: 'orange', label: 'Arancio'},
    {value: 'pink', label: 'Rosa'},
    {value: 'purple', label: 'Viola'},
    {value: 'red', label: 'Rosso'},
    {value: 'teal', label: 'Verde acqua'},
    {value: 'yellow', label: 'Giallo'},
  ]
}
