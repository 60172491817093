import React from 'react'
import InnerNavbar from './Navbar'
import {useNavigate, useLocation} from 'react-router-dom'

function Navbar(props) {
  const navigate = useNavigate()
  const location = useLocation()

  // const onLogout = () => console.log('logout')
  // console.log({match, location, navigate})
  const handleTabChange = (e, tab) => {
    // console.log('handleTabChange, tab', tab)
    switch (tab) {
      case 0:
        navigate('/operators')
        break
      case 1:
        navigate('/map')
        break
      case 2:
        navigate('/customers')
        break
      case 3:
        navigate('/gbpeople')
        break
      case 4:
        navigate('/pushdeck')
        break
      case 5:
        navigate('/settings')
        break
      default:
        navigate('/operators')
        break
    }
  }
  let tabValue = 0
  const {pathname = '/operators'} = location
  if (pathname.startsWith('/operators')) {
    tabValue = 0
  } else if (pathname.startsWith('/map')) {
    tabValue = 1
  } else if (pathname.startsWith('/customers')) {
    tabValue = 2
  } else if (pathname.startsWith('/gbpeople')) {
    tabValue = 3
  } else if (pathname.startsWith('/pushdeck')) {
    tabValue = 4
  } else if (pathname.startsWith('/settings')) {
    tabValue = 5
  }

  return (
    <>
      <InnerNavbar
        handleTabChange={handleTabChange}
        tabValue={tabValue}
        {...props}
      />
    </>
  )
}

export default Navbar
