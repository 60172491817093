import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
  MarkerClusterer,
} from '@react-google-maps/api'
import {mapDefaultLongitude, mapDefaultLatitude} from '../../Config'
import MapPopup from './MapPopup'
import {TrackingStates} from '../../utilities/tracking-utils'

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY

function AdminMap({operators = [], mapCenter, setMapInstance, onDetail}) {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: API_KEY,
    language: 'IT-it',
  })

  const onLoad = (map) => {
    // console.log({operators})
    // if (operators.length > 0) {
    //   let bounds = new window.google.maps.LatLngBounds()
    //   operators.map(item => {
    //     bounds.extend(
    //       new window.google.maps.LatLng(item.latitude, item.longitude)
    //     )
    //     return item.userId
    //   })
    //   map.fitBounds(bounds)
    // }
    setMapInstance(map)
  }

  const [popup, setPopup] = useState(null)
  const changePopup = (lat, lng, id) => {
    // console.log('setting popup', lat, lng, id)
    setPopup({lat, lng, id})
  }
  // console.log('operators', operators)
  // const selectedOperator = operators.find(o => o.userId === popup.id)
  // console.log('selectedOperator', selectedOperator)
  const closePopup = () => {
    // console.log('closing popup')
    setPopup(null)
  }

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    return (
      <GoogleMap
        options={{
          streetViewControl: false,
        }}
        id="circle-example"
        mapContainerStyle={{
          flex: 1,
          height: '100%',
          width: '100%',
        }}
        zoom={7}
        // streetView={false}
        // onCenterChanged={onCenterChanged}
        // onLoad={setMapInstance}
        onLoad={onLoad}
        onClick={closePopup}
        center={mapCenter}>
        <MarkerClusterer
          maxZoom={21}
          gridSize={30}
          options={{
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          }}>
          {(clusterer) => {
            return operators.map((operator) => {
              const {
                latitude: lat = mapDefaultLatitude,
                longitude: lng = mapDefaultLongitude,
                color,
                userId,
              } = operator
              const icon = {
                url:
                  operator.trackingState === TrackingStates.TRACKING
                    ? require(`../../assets/images/pins/operators/svg/${color}.svg`)
                    : require(`../../assets/images/pins/operators/svg/${color}-alpha.svg`),
              }
              // if (!!window.google && !!window.google.maps) {
              //   icon = {
              //     url: require(`../../assets/images/pins/operators/svg/${color}.svg`),
              //     size: new window.google.maps.Size(48, 48),
              //   }
              // }
              return (
                <Marker
                  key={userId}
                  onClick={() => changePopup(lat, lng, userId)}
                  position={{lat, lng}}
                  clusterer={clusterer}
                  icon={icon}
                />
              )
            })
          }}
        </MarkerClusterer>
        {popup != null && (
          <InfoWindow
            onLoad={(infoWindow) => {
              // console.log('infoWindow: ', infoWindow)
            }}
            onCloseClick={closePopup}
            position={{lat: popup.lat, lng: popup.lng}}>
            <MapPopup
              onDetail={() => onDetail(popup.id)}
              {...operators.find((o) => o.userId === popup.id)}
            />
          </InfoWindow>
        )}
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <div>Loading</div>
}

AdminMap.propTypes = {
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      surname: PropTypes.string,
      color: PropTypes.string.isRequired,
      // workHours: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      lastPositions: PropTypes.array.isRequired,
      lastState: PropTypes.object.isRequired,
      trackingState: PropTypes.oneOf(Object.values(TrackingStates)),
    })
  ).isRequired,
}

export default AdminMap
