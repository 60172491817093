import React, {createContext, useState} from 'react'

const initialState = {
  campaigns: [],
  selectedCampaign: null,
  singleRequest: {
    fetching: false,
    error: null,
  },
  listRequest: {
    fetching: false,
    error: null,
  },
  postRequest: {
    sending: false,
    error: null,
    success: false,
  },
}

const CampaignsContext = createContext([{}, () => {}])
const CampaignsProvider = (props) => {
  const [state, setState] = useState(initialState)
  return (
    <CampaignsContext.Provider value={[state, setState]}>
      {props.children}
    </CampaignsContext.Provider>
  )
}

export {CampaignsContext, CampaignsProvider}
