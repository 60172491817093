import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {format} from 'date-fns'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@material-ui/core/Link'

function desc(a, b, orderField) {
  const orderBy = orderField === 'date' ? 'serverTimestamp' : orderField
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const headRows = [
  {
    id: 'name',
    disablePadding: true,
    columnPadding: true,
    label: 'Operatore',
    align: 'left',
  },
  {id: 'date', align: 'center', disablePadding: false, label: 'Data'},
  {
    id: 'hour',
    align: 'center',
    disablePadding: false,
    label: 'Ora',
    disableSort: true,
  },
]

const useHeadStyles = makeStyles((theme) => ({
  columnPadding: {
    paddingLeft: theme.spacing(4),
  },
}))

function EnhancedTableHead(props) {
  const classes = useHeadStyles()
  const {order, orderBy, onRequestSort} = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            className={clsx({[classes.columnPadding]: row.columnPadding})}
            key={row.id}
            align={row.align || 'left'}
            // padding="none"
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}>
            {row.disableSort ? (
              row.label
            ) : (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}>
                {row.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  columnPadding: {
    paddingLeft: theme.spacing(4),
  },
}))

function DetailCheckins({checkins = []}) {
  // console.log(JSON.stringify(checkins, null, 2))
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('date')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const rows = checkins.map((c) => ({
    ...c,
    name: `${c.operator.surname} ${c.operator.name}`,
    id: `${c.userId}-${c.serverTimestamp}`,
    date: format(new Date(c.serverTimestamp), 'dd/MM/yyyy'),
    hour: format(new Date(c.serverTimestamp), 'HH:mm'),
  }))

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    <TableCell
                      className={classes.columnPadding}
                      component="th"
                      scope="row"
                      padding="none">
                      <Link
                        to={`/operators/${row.userId}`}
                        component={RouterLink}>
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.hour}</TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{height: 49 * emptyRows}}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Pagina precedente',
        }}
        nextIconButtonProps={{
          'aria-label': 'Pagina successiva',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Righe per pagina"
        labelDisplayedRows={({from, to, count}) => `${from}-${to} di ${count}`}
      />
    </div>
  )
}

DetailCheckins.propTypes = {
  checkins: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      operator: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
      }).isRequired,
      serverTimestamp: PropTypes.string.isRequired,
    })
  ),
}

export default DetailCheckins
