import React from 'react'
import * as Icons from 'react-feather'
import {Alert} from 'react-bootstrap'

export default function NewCampaignKoAlert() {
  return (
    <Alert variant="danger">
      <Alert.Heading>
        <Icons.AlertTriangle className="mr-1 alert-icon " />
        Errore
      </Alert.Heading>
      Impossibile creare la campagna, si prega di riprovare in seguito.
    </Alert>
  )
}
