import React, {useContext} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import useAxios from 'axios-hooks'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import {useAuthenticator} from '@aws-amplify/ui-react'
import GbpCustomerDetail from './GbpCustomerDetail'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'
import {OperatorsCacheContext} from '../../Contexts/OperatorsCacheContext'
import {CustomerTypologiesCacheContext} from '../../Contexts/CustomerTypologiesCaceContext'
import {ContactReasonsCacheContext} from '../../Contexts/ContactReasonsCacheContext'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
  },
}))

// avoid browser cache
let now = Date.now()

function GbpCustomerDetailPage(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const {customerId} = useParams()
  // const [operatorsCache, setOperatorsCache] = React.useState([])
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  const [operatorsCache] = useContext(OperatorsCacheContext)
  const [contactReasonsCache] = useContext(ContactReasonsCacheContext)
  const [customerTypologiesCache] = useContext(CustomerTypologiesCacheContext)

  React.useEffect(() => {
    now = Date.now()
  }, [])
  const [
    {
      data: customerDataResponse,
      loading: loadingCustomer,
      error: errorCustomer,
    },
  ] = useAxios({
    url: `${apiBaseUrl}/gbp/customers/${customerId}/?fakeTimestamp=${now}`,
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    timeout: normalApiTimeout,
  })

  const [
    {
      data: contactedOpsResponse,
      loading: loadingContactedOps,
      error: errorContactedOps,
    },
  ] = useAxios({
    url: `${apiBaseUrl}/gbp/customers/${customerId}/operators?fakeTimestamp=${now}`,
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    timeout: normalApiTimeout,
  })

  const fetching = loadingCustomer || loadingContactedOps
  const customer =
    customerDataResponse && customerDataResponse.success
      ? customerDataResponse.data
      : {}
  customer.verified = !!customer?.cognito?.verified

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <GbpCustomerDetail
          customerData={customer}
          contactedOps={
            contactedOpsResponse && contactedOpsResponse.success
              ? contactedOpsResponse.data
              : []
          }
          loadingCustomer={loadingCustomer}
          onBack={() => navigate('/gbpeople')}
          operators={operatorsCache}
          customerTypologies={customerTypologiesCache}
          contactReasons={contactReasonsCache}
          // onEdit={() =>
          //   navigate(`/customers/${customerHashKey}/${customerId}/edit`)
          // }
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <ErrorSnackbar
        response={customerDataResponse}
        error={errorCustomer || errorContactedOps}
        errorMessage={'Impossibile caricare tutti i dati del cliente GbPeople'}
      />
    </div>
  )
}

GbpCustomerDetailPage.propTypes = {}

export default GbpCustomerDetailPage
