import React, {useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
// import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {scrollToTop} from '../../utilities/layout-utils'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  container: {
    marginLeft: theme.spacing(4),
    maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  verticalSpacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    // backgroundColor: 'green',
    display: 'flex',
    justifyContent: 'space-between',
    // borderBottom: '2px solid #C4CDD5',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridContainer: {
    marginBottom: theme.spacing(3),
  },
  button: {
    // margin: theme.spacing(1),
    minWidth: theme.spacing(34),
  },
}))

function SettingsForm(props) {
  const classes = useStyles()
  const {
    oldPassword,
    password,
    confirmPassword,
    setPassword,
    setOldPassword,
    setConfirmPassword,
    onSave,
    errors,
    saving = false,
  } = props

  useLayoutEffect(scrollToTop, [])

  // const formDisabled = saving
  const checkEqualPassword = () =>
    !confirmPassword || confirmPassword === password
  const isFormValid =
    !!oldPassword && password === confirmPassword && password.length >= 8
  const onSubmit = (e) => {
    e.preventDefault()
    if (isFormValid) {
      onSave(oldPassword, password)
    }
  }
  const arePasswordDifferent = !checkEqualPassword()
  const isPasswordTooShort = !!password && password.length < 8

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h5">Impostazioni</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <form
          id="customerEditForm"
          className={classes.formContainer}
          noValidate
          onSubmit={onSubmit}
          autoComplete="off">
          <Grid container className={classes.gridContainer} spacing={3}>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <TextField
                id="oldPassword"
                name="oldPassword"
                label="Password attuale"
                className={classes.textField}
                margin="normal"
                type="password"
                fullWidth
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                error={!!errors.oldPassword}
                helperText={errors.oldPassword || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              {' '}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <TextField
                id="password"
                name="password"
                label="Nuova password"
                className={classes.textField}
                margin="normal"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!errors.password}
                helperText={
                  isPasswordTooShort
                    ? 'La password deve avere almeno 8 caratteri'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              {' '}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="Conferma nuova password"
                className={classes.textField}
                margin="normal"
                type="password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!errors.confirmPassword}
                helperText={
                  arePasswordDifferent ? 'Le password non corrispondono' : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              {' '}
            </Grid>
          </Grid>

          <Button
            id="customerSubmit"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={saving || !isFormValid}
            type="submit">
            Salva
          </Button>
        </form>
      </div>
    </div>
  )
}

SettingsForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  errorSaving: PropTypes.object,
  saveResponseData: PropTypes.any,
}

export default SettingsForm
