// import apisauce from 'apisauce'
import axios from 'axios'
import {Auth} from 'aws-amplify'

const BASE_URL = process.env.REACT_APP_PUSHDECK_API

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Cache-Control': 'no-cache',
  },
  // 10 second timeout...
  timeout: 10000,
})

const getBearerConfig = async () => {
  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()
  return {
    headers: {Authorization: `Bearer ${token}`},
  }
}

// export const login = async (username, password) => {
//   const {data} = await api.post('/login', {username, password})
//   return data
// }

// export const getMe = async () => api.get('/me', await getBearerConfig())

export const getApp = async (appId = 'default') =>
  api.get(`/apps/${appId}`, await getBearerConfig())

// #region topics
export const getTopics = async (withCampaigns = false) =>
  api.get(
    `/topics${withCampaigns ? '?campaigns=true' : ''}`,
    await getBearerConfig()
  )

export const getTopic = async (id) =>
  api.get(`/topics/${id}`, await getBearerConfig())

export const postNewTopic = async (topicName) =>
  api.post('/topics', {name: topicName}, await getBearerConfig())

export const editTopic = async (topicId, topicName) =>
  api.patch(`/topics/${topicId}`, {name: topicName}, await getBearerConfig())

export const launchUpdateTopicInfoTask = async () =>
  api.post('/update-topic-info', null, await getBearerConfig())
// #endregion topics

// #region devices
export const getDeviceStats = async (days = 7) =>
  api.get(`/devices?days=${days}`, await getBearerConfig())
// #endregion devices

// #region campaigns
export const getCampaigns = async () =>
  api.get('/campaigns', await getBearerConfig())

export const getCampaign = async (id) =>
  api.get(`/campaigns/${id}`, await getBearerConfig())

export const postCampaign = async (values) => {
  const {
    name,
    title,
    body,
    url,
    imageUrl,
    target,
    topic,
    selectedDate,
    isScheduled = false,
  } = values
  const data = url || imageUrl ? {} : null
  if (url) {
    data.url = url
  }
  if (imageUrl) {
    data.urlImageString = imageUrl
  }
  const destTarget = {}
  switch (target) {
    case 'ios':
      destTarget.platform = 'ios'
      break
    case 'android':
      destTarget.platform = 'android'
      break
    case 'topic':
      destTarget.topic = topic
      break
    default:
      destTarget.all = true
      break
  }
  return api.post(
    '/campaigns',
    {
      name,
      title,
      body,
      data,
      target: destTarget,
      isScheduled,
      startDate: selectedDate,
    },
    await getBearerConfig()
  )
}
// #endregion campaigns
