import React from 'react'
import {Card, Accordion, Button, Form} from 'react-bootstrap'

export default function StepContent(props) {
  const {setActiveKey, bindTitle, bindText, bindUrl, bindImageUrl} = props
  const handleSubmit = (evt) => {
    evt.preventDefault()
    setActiveKey('scheduler')
  }
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="content"
          onClick={() => setActiveKey('content')}>
          <span className="stepActive mr-3">2</span>Contenuti
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="content">
        <Card.Body className="ml-4">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="exampleForm.PushTitle">
              <Form.Label className="formLabel">
                Titolo della notifica
              </Form.Label>
              <Form.Control
                className="form-control-lg"
                type="text"
                placeholder="Inserisci un titolo facoltativo"
                {...bindTitle}
              />
              <small className="form-text text-muted">
                Solo per dispositivi Android e WatchOs
              </small>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="formLabel">
                Testo della notifica
              </Form.Label>
              <Form.Control
                className="form-control-lg"
                as="textarea"
                rows="3"
                placeholder="Inserisci qui il testo della notifica"
                {...bindText}
              />
              <small className="form-text text-muted">Max 176 caratteri</small>
            </Form.Group>
            <Form.Group controlId="exampleForm.PushUrl">
              <Form.Label className="formLabel">URL della notifica</Form.Label>

              <Form.Control
                className="form-control-lg"
                type="text"
                placeholder="es: http://www.airbagstudio.it"
                {...bindUrl}
              />

              <small className="form-text text-muted">
                Cliccando la notifica gli utenti verranno portati a questa URL
              </small>
            </Form.Group>
            {/* <div className="d-flex align-items-center justify-content-between"> */}
            <Form.Group controlId="exampleForm.ImageUrl">
              <Form.Label className="formLabel">
                URL dell'immagine di anteprima
              </Form.Label>

              <Form.Control
                className="form-control-lg"
                type="text"
                placeholder="es: http://placeimg.com/640/480/tech"
                {...bindImageUrl}
              />

              <small className="form-text text-muted">
                Dimensione massima: 1MB
              </small>
            </Form.Group>
            <div className="d-flex justify-content-between pt-4">
              <Button
                className="btn"
                size="lg"
                onClick={() => setActiveKey('target')}>
                indietro
              </Button>
              <Button type="submit" className="btn" size="lg">
                avanti
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}
