import React, {useState, useEffect} from 'react'
import CustomSnackbar from './CustomSnackbar'

export default function SaveSnackbar(props) {
  const {
    response,
    error,
    errorMessage = "Errore durante l'operazione",
    ...otherProps
  } = props
  const [state, setState] = useState({
    open: false,
    message: '',
    variant: 'error',
  })
  const {open, message, variant} = state
  const setOpen = (value) => setState({...state, open: value})
  useEffect(() => {
    if (!response && !error) {
      setState({
        ...state,
        open: false,
      })
    } else if (error) {
      setState({
        open: true,
        message: errorMessage,
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error, errorMessage])

  return (
    <CustomSnackbar
      open={open}
      setOpen={setOpen}
      variant={variant}
      message={message}
      {...otherProps}
    />
  )
}
