import React from 'react'
// import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import useAxios from 'axios-hooks'
import {useParams, useNavigate} from 'react-router-dom'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import {useAuthenticator} from '@aws-amplify/ui-react'
import CustomerEdit from './CustomerEdit'
import SaveSnackbar from '../CustomSnackbar/SaveSnackbar'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
  },
}))

// avoid browser cache
let now = Date.now()

function CustomerEditPage(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const {customerHashKey, customerId} = useParams()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  React.useEffect(() => {
    now = Date.now()
  }, [])
  const [
    {data: customerRawData, loading: loadingCustomer, error: errorCustomer},
  ] = useAxios({
    url: `${apiBaseUrl}/customers/${customerId}?customerHashKey=${customerHashKey}&fakeTimestamp=${now}`,
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
    timeout: normalApiTimeout,
  })

  const [
    {data: saveResponse, loading: savingCustomer, error: errorSaving},
    executeSave,
  ] = useAxios(
    {
      url: `${apiBaseUrl}/customers/${customerId}`,
      method: 'PATCH',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    },
    {manual: true}
  )

  let customerData = customerRawData
  if (customerRawData) {
    const rawData =
      saveResponse && saveResponse.success
        ? saveResponse.customer
        : customerRawData
    const {disabled = false, ...customer} = rawData
    customerData = {
      ...customer,
      disabled,
    }
  }

  const onDisable = async () => {
    const {customerId: id, latitude, longitude, ...rawdata} = customerRawData
    executeSave({
      data: {...rawdata, disabled: true},
    })
  }
  const onEnable = async () => {
    const {customerId: id, latitude, longitude, ...rawdata} = customerRawData
    executeSave({
      data: {...rawdata, disabled: false},
    })
  }

  const onSave = async (flatData, formikBag) => {
    // console.log('flatData', flatData)
    // console.log('formikBag', formikBag)
    const {customerId: id, latitude, longitude, ...data} = flatData
    await executeSave({
      data,
    })
    formikBag.setSubmitting(false)
  }
  const fetching = loadingCustomer || savingCustomer

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <CustomerEdit
          savingCustomer={savingCustomer}
          customerData={customerData}
          loadingCustomer={loadingCustomer}
          onDisable={onDisable}
          onEnable={onEnable}
          onSave={onSave}
          onBack={() => navigate(`/customers/${customerHashKey}/${customerId}`)}
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <SaveSnackbar response={saveResponse} error={errorSaving} />
      <ErrorSnackbar
        response={customerRawData}
        error={errorCustomer}
        errorMessage={'Impossibile caricare tutti i dati del cliente'}
      />
    </div>
  )
}

CustomerEditPage.propTypes = {}

export default CustomerEditPage
