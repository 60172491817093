import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LabeledCell from '../DetailLabeledCell'
import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button'
import {format} from 'date-fns'

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft: theme.spacing(4),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  gridContainerTitle: {
    // backgroundColor: 'green',
    borderBottom: '2px solid #C4CDD5',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  gridContainer: {
    // backgroundColor: 'green',
  },
  gridItem: {
    // backgroundColor: 'red',
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

function DetailInfo(props) {
  const classes = useStyles()
  const {
    companyName = '-',
    companyAddress = '-',
    firstName = '-',
    lastName = '-',
    latitude = '-',
    longitude = '-',
    createdAt,
    notificationsRange = '-',
    notificationsEnabled,
    email = '-',
    phoneNumber = '-',
    enabled,
    typologyId,
    customTypology,
    customerTypologies = [],
    verified = true,
    // loadingCustomer,
    // onEdit,
  } = props
  let typologyDesc = ''
  if (typologyId != null) {
    const typology = customerTypologies.find((ct) => ct.id === typologyId)
    if (typology) {
      typologyDesc = typology.label
    }
    if (typologyId === 0 && !!customTypology) {
      typologyDesc = `${typology.label} (${customTypology})`
    }
  }
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainerTitle}>
        <Grid item xs={10} className={classes.gridItemTitle}>
          <Typography variant="h5">
            {companyName}
            {!enabled && <small>{' - DISABILITATO'}</small>}
          </Typography>
          {typologyId != null && (
            <Typography variant="subtitle2">
              Tipologia: {typologyDesc}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          {/* <Button
            color="primary"
            className={classes.button}
            size="small"
            disabled={loadingCustomer}
            onClick={onEdit}
            variant="contained">
            Modifica
          </Button> */}{' '}
        </Grid>
      </Grid>

      <Grid container className={classes.gridContainer}>
        <Grid item xs={10} className={classes.gridItem}>
          <LabeledCell label="Indirizzo" description={companyAddress} />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Referente"
            description={`${firstName} ${lastName}`}
          />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Data Iscrizione"
            description={
              createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : '-'
            }
          />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Email"
            description={email}
            descriptionError={!verified ? '(non verificato)' : undefined}
          />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Telefono" description={phoneNumber} />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Notifiche"
            description={notificationsEnabled ? 'Attive' : 'Disattivate'}
          />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell
            label="Range notifiche (km)"
            description={
              notificationsEnabled && notificationsRange
                ? notificationsRange / 1000
                : '-'
            }
          />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>

        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Latitudine" description={latitude} />
        </Grid>
        <Grid item xs={5} className={classes.gridItem}>
          <LabeledCell label="Longitudine" description={longitude} />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          {' '}
        </Grid>
      </Grid>
    </div>
  )
}

DetailInfo.propTypes = {
  // onEdit: PropTypes.func.isRequired,
  loadingCustomer: PropTypes.bool.isRequired,
  customerTypologies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  createdAt: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  notificationsRange: PropTypes.number,
  notificationsEnabled: PropTypes.bool,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  enabled: PropTypes.bool,
  typologyId: PropTypes.number,
}

export default DetailInfo
