import React from 'react'
// import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft: theme.spacing(4),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  titleContainer: {
    // backgroundColor: 'green',
    display: 'flex',
    justifyContent: 'space-between',
    // borderBottom: '2px solid #C4CDD5',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}))

function OperatorNewTitle() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h5">Nuovo operatore</Typography>
      </div>
    </div>
  )
}

OperatorNewTitle.propTypes = {}

export default OperatorNewTitle
