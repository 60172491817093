import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    // top: 'auto',
    // bottom: 0,
    // height: '40px',
  },
  link: {
    marginRight: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(50),
  },
}))

// This resolves to nothing and doesn't affect browser navigate
// eslint-disable-next-line no-script-url
const dudUrl = 'javascript:;'

function Footer({disableTopMargin = false}) {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.topMargin]: !disableTopMargin,
      })}>
      <div className={classes.root}>
        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          className={classes.appBar}>
          <Toolbar variant="dense">
            <Typography variant="body2">
              <Link href={dudUrl} className={classes.link}>
                Privacy Policy
              </Link>
              <Link href={dudUrl} className={classes.link}>
                Contatti
              </Link>
              <Link href={dudUrl} className={classes.link}>
                Chi siamo
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  )
}

Footer.propTypes = {
  disableTopMargin: PropTypes.bool,
}

export default Footer
