import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import {compareDesc} from 'date-fns'
import Grid from '@material-ui/core/Grid'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import UnreachableIcon from '@material-ui/icons/WifiOff'
import InvalidPositionIcon from '@material-ui/icons/LocationOffOutlined'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import {TrackingStates, getTrackingStatus} from '../../utilities/tracking-utils'
import {MovingStates, getMovingStatus} from '../../utilities/moving-utils'

function movingStateToLabel(state) {
  switch (state) {
    case MovingStates.MOVING:
      return 'In movimento'
    case MovingStates.UNREACHABLE:
      return 'Irraggiungibile'
    case MovingStates.INVALID_POSITION:
      return ''
    default:
      return 'Fermo'
  }
}

function trackingStateToLabel(state) {
  switch (state) {
    case TrackingStates.TRACKING:
      return 'In corso'
    case TrackingStates.UNREACHABLE:
      return 'Irraggiungibile'
    case TrackingStates.INVALID_POSITION:
      return 'Posizione non valida'
    default:
      return 'Non in corso'
  }
}

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    // display: 'flex',
    // width: '100%',
    // justifyContent: 'space-between',
  },
  statusContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  success: {
    color: green[500],
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: orange[500],
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  trackContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function OperatorStatus(props) {
  const {fullSize = false, lastPositions = [], lastState = {}} = props

  const orderedPositions = lastPositions
    .map(
      ({
        latitude,
        longitude,
        serverTimestamp,
        isInvalid,
        invalidPositionTimestamp,
      }) => ({
        latitude,
        longitude,
        serverTimestamp,
        isInvalid,
        invalidPositionTimestamp,
      })
    )
    .sort((a, b) => compareDesc(a, b))
  const lastPosition = orderedPositions.length > 0 ? orderedPositions[0] : null

  let trackingState = getTrackingStatus(lastState, lastPosition)

  const movingState = getMovingStatus(orderedPositions, trackingState)
  const trackingLabel = trackingStateToLabel(trackingState)
  const movingLabel = movingStateToLabel(movingState)
  // console.log({movingState, trackingLabel, movingLabel, trackingState})

  const classes = useStyles()
  return (
    <div className={classes.rootContainer}>
      <Grid container className={classes.gridContainerTitle}>
        <Grid item xs={fullSize ? 6 : 5} className={classes.gridItemTitle}>
          <div className={classes.statusContainer}>
            <Typography variant="subtitle2">
              <b>Tracciamento</b>
            </Typography>
            <div className={classes.trackContainer}>
              {trackingState === TrackingStates.TRACKING ? (
                <DoneIcon
                  fontSize="default"
                  className={clsx(classes.icon, classes.success)}
                />
              ) : trackingState === TrackingStates.UNREACHABLE ? (
                <UnreachableIcon
                  fontSize="default"
                  className={clsx(classes.icon, classes.warning)}
                />
              ) : trackingState === TrackingStates.INVALID_POSITION ? (
                <InvalidPositionIcon
                  fontSize="default"
                  className={clsx(classes.icon, classes.warning)}
                />
              ) : (
                <ClearIcon
                  fontSize="default"
                  className={clsx(classes.icon, classes.error)}
                />
              )}
              <Typography variant="body2" display="inline">
                {trackingLabel}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={fullSize ? 6 : 5} className={classes.gridItemTitle}>
          <div className={classes.statusContainer}>
            <Typography variant="subtitle2">
              <b>Stato</b>
            </Typography>
            <Typography variant="body2">{movingLabel}</Typography>
          </div>
        </Grid>
        {!fullSize && (
          <Grid item xs={2} className={classes.gridItem}>
            {' '}
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default OperatorStatus
