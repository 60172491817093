import React, {useCallback, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import NavBar from '../Navbar'
import AdminMap from './AdminMap'
import Footer from '../Footer'
import {useAuthenticator} from '@aws-amplify/ui-react'
import useAxios from 'axios-hooks'
import {
  adminMapPollingSeconds,
  apiBaseUrl,
  mapDefaultCenter,
  normalApiTimeout,
} from '../../Config'
import useInterval from '../../Hooks/Interval'
import {isAfter, startOfDay, addSeconds} from 'date-fns'
import {useNavigate} from 'react-router-dom'
import {Auth} from 'aws-amplify'
import {getTrackingStatus} from '../../utilities/tracking-utils'

function createData(
  userId,
  disabled = false,
  name,
  surname,
  color,
  lastPositions = [],
  lastStates = []
) {
  const lastPosition = lastPositions.length === 0 ? {} : lastPositions[0]
  const {latitude, longitude} = lastPosition
  const lastState = lastStates.length === 0 ? null : lastStates[0]
  const trackingState = getTrackingStatus(lastState, lastPosition)

  return {
    userId,
    disabled,
    name,
    surname,
    color,
    latitude,
    longitude,
    lastPositions,
    lastState,
    trackingState,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    // flexGrow: 1,
    flex: 1,
    // backgroundColor: 'red',
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}))

function Map() {
  const classes = useStyles()
  const navigate = useNavigate()
  let operators = []
  const [token, setToken] = useState()
  const [firstZoom, setFirstZoom] = useState(true)
  const mapRef = useRef(null)
  const setMapInstance = (map) => {
    mapRef.current = map
  }
  const onDetail = (userId) => navigate(`/operators/${userId}`)
  const {signOut} = useAuthenticator((context) => [context.user])
  // const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  const [
    {data: responseData, loading: fetching, error: requestError},
    executeRequest,
  ] = useAxios(
    {
      url: `${apiBaseUrl}/positions?state=true&limit=3`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    },
    {manual: true}
  )
  const getCurrentTokenAndExecute = useCallback(async () => {
    try {
      const user = await Auth.currentSession()
      const currentToken = user.getAccessToken().getJwtToken()
      setToken(currentToken)
      executeRequest()
    } catch (error) {
      console.error('Impossible to retrieve current session')
      console.error(error)
      setToken(null)
    }
  }, [executeRequest])

  if (!fetching && !requestError && responseData) {
    // console.log('responseDate', responseData)
    operators = responseData
      .map((operator) =>
        createData(
          operator.userId,
          operator.disabled,
          operator.name,
          operator.surname,
          operator.color,
          operator.lastPositions,
          operator.lastStates
        )
      )
      .filter(
        ({disabled = false, latitude, longitude, color}) =>
          !disabled && latitude != null && longitude != null && color
      )
      .filter(
        (operator) =>
          operator.lastPositions &&
          operator.lastPositions.length > 0 &&
          operator.lastPositions[0].serverTimestamp &&
          !operator.lastPositions[0].isInvalid &&
          isAfter(
            new Date(operator.lastPositions[0].serverTimestamp),
            addSeconds(startOfDay(new Date()), -1)
          )
      )
  }

  useEffect(() => {
    getCurrentTokenAndExecute()
  }, [getCurrentTokenAndExecute])

  useEffect(() => {
    if (firstZoom && operators && operators.length > 0 && mapRef.current) {
      let bounds = new window.google.maps.LatLngBounds()
      operators.map((item) => {
        bounds.extend(
          new window.google.maps.LatLng(item.latitude, item.longitude)
        )
        return item.userId
      })
      mapRef.current.fitBounds(bounds)
      setFirstZoom(false)
    }
  }, [mapRef, operators, firstZoom])

  useInterval(() => {
    if (!fetching) {
      // console.log()
      getCurrentTokenAndExecute()
    }
  }, adminMapPollingSeconds * 1000)

  let mapCenter = mapDefaultCenter
  if (mapRef.current) {
    const center = mapRef.current.getCenter()
    if (center) {
      mapCenter = {
        lat: center.lat(),
        lng: center.lng(),
      }
    }
  }

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin disableBottomMargin onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <div className={classes.absolute}>
          <AdminMap
            operators={operators}
            mapCenter={mapCenter}
            // setMapCenter={setMapCenter}
            setMapInstance={setMapInstance}
            onDetail={onDetail}
          />
        </div>
      </main>
      <footer>
        <Footer disableTopMargin />
      </footer>
    </div>
  )
}

export default Map
