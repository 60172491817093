import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft: theme.spacing(4),
    // maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  titleContainer: {
    // backgroundColor: 'green',
    display: 'flex',
    justifyContent: 'space-between',
    // borderBottom: '2px solid #C4CDD5',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}))

function OperatorEditTitle({
  userDisabled,
  buttonDisabled,
  onDisable,
  onEnable,
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h5">
          Modifica operatore{userDisabled && <small>{' - DISABILITATO'}</small>}
        </Typography>
        {userDisabled ? (
          <Button
            color="primary"
            className={classes.button}
            size="small"
            disabled={buttonDisabled}
            onClick={onEnable}
            variant="contained">
            Abilita operatore
          </Button>
        ) : (
          <Button
            color="primary"
            className={classes.button}
            size="small"
            disabled={buttonDisabled}
            onClick={onDisable}
            variant="contained">
            Disabilita operatore
          </Button>
        )}
      </div>
    </div>
  )
}

OperatorEditTitle.propTypes = {
  userDisabled: PropTypes.bool.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
}

export default OperatorEditTitle
