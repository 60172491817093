import React from 'react'
import PropTypes from 'prop-types'
import * as Icons from 'react-feather'
import {Table} from 'react-bootstrap'
import {useTable, useFlexLayout, usePagination} from 'react-table'
import {format} from 'date-fns'
import OsIcons from '../OsIcons'
import Status from '../CampaignStatus'
import Stars from '../Stars'
import TopicBadge from '../TopicBadge'
import Pagination from '../Pagination'

Statistics.propTypes = {
  totalSent: PropTypes.number.isRequired,
  opened: PropTypes.number.isRequired,
}

function Statistics({totalSent, opened, percentage}) {
  return (
    <span>
      {totalSent}/{opened} <span className="percentOpen">({percentage}%)</span>
    </span>
  )
}

function getOsList(type) {
  switch (type) {
    case 'android':
      return ['android']
    case 'ios':
      return ['ios']
    default: {
      return ['android', 'ios']
    }
  }
}

CampaignUrl.propTypes = {
  url: PropTypes.string.isRequired,
}

function CampaignUrl({url}) {
  if (!url) {
    return null
  }
  const maxLength = 40
  const isLong = url.length > maxLength
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {isLong ? url.substring(0, maxLength) + '...' : url}
    </a>
  )
}

function isCompleted(status) {
  return status === 'completed'
}

function calcRate(sent, opened) {
  return sent === 0 ? 0 : Math.round((opened / sent) * 100)
}

CampaignsTableComponent.propTypes = {
  campaigns: PropTypes.array,
  topics: PropTypes.array,
  onRowClick: PropTypes.func.isRequired,
  enableProgressive: PropTypes.bool,
  hideTopics: PropTypes.bool,
}

function CampaignsTableComponent(props) {
  const {
    campaigns = [],
    // currentPage,
    // handlePage,
    topics,
    // onRowClick,
    enableProgressive = false,
    hideTopics = false,
  } = props
  let hiddenColumns = []
  if (!enableProgressive) {
    hiddenColumns.push('progressive')
  }
  if (hideTopics) {
    hiddenColumns.push('campaignTopics')
  }
  const data = React.useMemo(() => campaigns, [campaigns])
  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'progressive',
        width: 30,
      },
      {
        Header: 'Data di invio',
        tdClass: 'campaignDate',
        width: 80,
        accessor: 'startDate',
        Cell: ({value}) => format(new Date(value), 'dd/MM/yyyy'),
      },
      {
        Header: 'Nome',
        width: 150,
        tdClass: 'campaignTitle',
        accessor: 'name',
      },
      {
        Header: 'Testo',
        width: 200,
        tdClass: 'campaignTxt',
        accessor: 'body',
      },
      {
        Header: 'IMG',
        width: 40,
        tdClass: 'campaignImage',
        accessor: (row) => !!(row.data || {}).urlImageString,
        id: 'img',
        Cell: ({value}) => (value ? <Icons.Check className="feather" /> : null),
      },
      {
        Header: 'URL',
        width: 250,
        tdClass: 'campaignUrl',
        accessor: (row) => (row.data || {}).url || '',
        id: 'url',
        Cell: ({value}) => <CampaignUrl url={value} />,
      },
      {
        Header: 'Os',
        width: 55,
        tdClass: 'campaignOs',
        accessor: (row) => {
          switch (row.type) {
            case 'android':
              return 'android'
            case 'ios':
              return 'ios'
            default:
              return 'all'
          }
        },
        id: 'os',
        Cell: ({value}) => {
          const osList = getOsList(value)
          return <OsIcons osList={osList} classes="mr-2" />
        },
      },
      {
        Header: 'Interessi',
        width: 150,
        tdClass: 'campaignTopic',
        accessor: 'campaignTopics',
        Cell: ({value}) => {
          if (!value || value.length === 0 || !topics || topics.length === 0) {
            return null
          }
          return (
            <>
              {value.map((topic) => (
                <TopicBadge key={topic.id} topics={topics} id={topic.id} />
              ))}
            </>
          )
        },
      },
      {
        Header: 'Inviati/Aperti',
        width: 90,
        tdClass: 'campaignResult',
        id: 'rate',
        accessor: (row) => calcRate(row.totalSent, row.opened),
        Cell: ({row, value}) => {
          const {status, opened, totalSent} = row.original
          if (!isCompleted(status)) {
            return '-'
          }
          return (
            <Statistics
              opened={opened}
              totalSent={totalSent}
              percentage={value}
            />
          )
        },
      },
      {
        Header: 'Successo',
        width: 100,
        tdClass: 'campaignStars',
        accessor: (row) => calcRate(row.totalSent, row.opened),
        id: 'success',
        Cell: (props) => {
          const {value, row} = props
          const {status} = row.original
          // const placement = row.index === 0 ? 'left' : 'top'

          if (!isCompleted(status)) {
            return null
          }
          return <Stars rate={value} enableTooltip placement="left" />
        },
      },
      {
        Header: 'Stato',
        width: 40,
        tdClass: 'campaignStatus',
        accessor: 'status',
        Cell: ({value}) => (
          <Status status={value} enableTooltip placement="left" />
        ),
      },
    ],
    [topics]
  )
  // const defaultColumn = React.useMemo(
  //   () => ({
  //     // When using the useFlexLayout:
  //     // minWidth: 30, // minWidth is only used as a limit for resizing
  //     width: 150, // width is used for both the flex-basis and flex-grow
  //     // maxWidth: 200, // maxWidth is only used as a limit for resizing
  //   }),
  //   []
  // )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,

    // ** pagination **
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state: {pageIndex},
  } = useTable(
    {
      columns,
      data,
      // defaultColumn,
      getRowId: (row) => row.id,
      initialState: {
        hiddenColumns,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
    },
    usePagination,
    useFlexLayout
  )
  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
  }
  return (
    <div className="table-responsive mt-3">
      <Table
        {...getTableProps()}
        responsive
        striped
        bordered
        hover
        size="sm"
        id="table"
        data-toggle="table"
        data-height="460">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((campaign) => {
            prepareRow(campaign)
            return (
              <tr
                {...campaign.getRowProps([
                  {
                    // onClick: () => onRowClick(campaign.id),
                  },
                ])}>
                {campaign.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.tdClass,
                        },
                      ])}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Pagination {...paginationProps} />
      </div>
    </div>
  )
}

export default CampaignsTableComponent
