import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faApple, faAndroid} from '@fortawesome/free-brands-svg-icons'

OsIcons.propTypes = {
  osList: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.string,
}

function OsIcons({osList = [], classes = ''}) {
  const filteredList = osList
    .map((item = '') => item.toLowerCase())
    .filter((item) => ['android', 'ios'].includes(item))
  if (filteredList.length === 0) {
    return null
  }
  return filteredList.map((item) => {
    let icon = item === 'ios' ? faApple : faAndroid
    return (
      <FontAwesomeIcon id={item} icon={icon} className={classes} key={item} />
    )
  })
}

export default OsIcons
