export const scrollToTop = function (behavior = 'auto') {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scroll({
      top: 0,
      left: 0,
      behavior,
    })
  } catch (error) {
    // just a fallback for older browsers
    window.scrollTo(0, 0)
  }
}
