import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Icons from 'react-feather'
import {Card, Accordion, Button, Spinner} from 'react-bootstrap'
import CampaignRecap from '../CampaignRecap'

export default function StepRecap(props) {
  const {
    setActiveKey,
    onSubmitCampaign,
    text: body,
    os: osList,
    onValidate,
    isSubmitting,
    topics,
    topic,
    isImmediate,
  } = props
  // const osList = os === ''
  const isValid = onValidate()
  const campaignTopics = topic ? topics.filter((t) => t.id === topic) : []
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="recap"
          onClick={() => setActiveKey('recap')}>
          <span className={clsx('step', 'mr-3', {'bg-success': isValid})}>
            <Icons.Check className="feather pb-1" />
          </span>
          Riepilogo
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="recap">
        <Card.Body className="ml-2">
          <CampaignRecap
            {...props}
            osList={osList}
            body={body}
            campaignTopics={campaignTopics || []}
            isNewCampaign
          />
          <div className="d-flex mb-0 pt-5 justify-content-between ">
            <Button
              className="btn-primary"
              size="lg"
              disabled={isSubmitting}
              onClick={() => setActiveKey('scheduler')}>
              indietro
            </Button>
            <Button
              className="btn-success"
              size="lg"
              disabled={!isValid || isSubmitting}
              onClick={onSubmitCampaign}>
              <Icons.Send className="feather mr-3" />
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Invio...
                </>
              ) : isImmediate ? (
                <span>Avvia la campagna</span>
              ) : (
                <span>Schedula campagna</span>
              )}
            </Button>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

StepRecap.propTypes = {
  setActiveKey: PropTypes.func.isRequired,
  onSubmitCampaign: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  os: PropTypes.array.isRequired,
  onValidate: PropTypes.func.isRequired,
}
