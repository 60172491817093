import React, {memo} from 'react'
import PropTypes from 'prop-types'
import * as Icons from 'react-feather'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

export function statusToDescription(status) {
  //'scheduled', 'starting', 'running', 'completed', 'error'
  switch (status) {
    case 'scheduled':
      return 'schedulata'
    case 'starting':
      return 'in preparazione'
    case 'running':
      return 'in corso'
    case 'completed':
      return 'completata'
    case 'error':
      return 'errore'
    default:
      return '-'
  }
}

Status.propTypes = {
  enableDescription: PropTypes.bool,
  enableTooltip: PropTypes.bool,
  placement: PropTypes.oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
  ]),
  status: PropTypes.oneOf([
    'scheduled',
    'starting',
    'running',
    'completed',
    'error',
  ]),
}

function StatusIcon({status}) {
  switch (status) {
    case 'scheduled':
      return <Icons.PauseCircle className="mr-2 yellow feather" />
    case 'starting':
      return (
        <img
          src="/imgs/Pulse-1s-200px.gif"
          alt="starting status"
          className="mr-2 feather"
        />
      )
    // return (
    //   <Spinner
    //     animation="border"
    //     variant="primary"
    //     className="mr-2 feather"
    //     size="sm"
    //   />
    // )
    // return <Icons.PlayCircle className="mr-2 yellow feather" />
    case 'completed':
      return <Icons.CheckCircle className="mr-2 green feather" />
    case 'error':
      return <Icons.XCircle className="mr-2 red feather" />
    case 'running':
      return (
        <img
          src="/imgs/Radio-1s-200px.gif"
          alt="starting status"
          className="mr-2 feather"
        />
      )

    // return (
    //   <Spinner
    //     animation="border"
    //     variant="primary"
    //     className="mr-2 feather"
    //     size="sm"
    //   />
    // )
    // return <Icons.Clock className="mr-2 yellow feather" />
    default:
      // undefined
      return null
  }
}

function StatusNoTooltip({status, enableDescription = false}) {
  if (enableDescription) {
    return (
      <span>
        {statusToDescription(status)}&nbsp;
        <StatusIcon status={status} />
      </span>
    )
  }
  return <StatusIcon status={status} />
}

function StatusWithTooltip({status, placement = 'top'}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={`campaign-status-tooltip-${placement}-${status}`}>
          {statusToDescription(status)}
        </Tooltip>
      }>
      <div>
        <StatusNoTooltip status={status} />
      </div>
    </OverlayTrigger>
  )
}

function Status({status, enableDescription, enableTooltip = false, placement}) {
  if (enableTooltip) {
    return <StatusWithTooltip status={status} placement={placement} />
  }
  return (
    <StatusNoTooltip status={status} enableDescription={enableDescription} />
  )
}

export default memo(Status)
