import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LinearProgress from '@material-ui/core/LinearProgress'
import logo from '../../assets/images/logo_bucciarelli_ext.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  bottomMargin: {
    marginBottom: theme.spacing(10),
  },
  logoContainer: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '50px',
  },
  toolbar: {
    // backgroundColor: 'blue',
  },
  tabs: {
    // backgroundColor: 'green',
    // alignSelf: 'stretch',
  },
  exitButton: {
    marginLeft: theme.spacing(10),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default function Navbar(props) {
  const {
    onLogout,
    isAdmin,
    tabValue = 0,
    handleTabChange,
    disableBottomMargin = false,
    loading = false,
  } = props
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, {
        [classes.bottomMargin]: !disableBottomMargin,
      })}>
      <AppBar elevation={0} position="fixed" color="inherit">
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <img src={logo} className={classes.logo} alt="logo" />
          </div>
          {/* <span className={classes.title} /> */}
          {isAdmin && (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabs}>
              <Tab label="Gestione operatori" />
              <Tab label="Mappa" />
              <Tab label="Clienti" />
              <Tab label="GbPeople" />
              <Tab label="Push" />
              <Tab label="Impostazioni" />
            </Tabs>
          )}
          <Button
            color="inherit"
            className={classes.exitButton}
            onClick={onLogout}>
            <ExitToAppIcon className={classes.leftIcon} />
            Esci
          </Button>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
    </div>
  )
}

Navbar.propTypes = {
  onLogout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  tabValue: PropTypes.number,
  handleTabChange: PropTypes.func,
  disableBottomMargin: PropTypes.bool,
  loading: PropTypes.bool,
}
