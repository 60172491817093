import React, {memo} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar as faStarSolid} from '@fortawesome/free-solid-svg-icons'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
// import {faStar} from '@fortawesome/free-regular-svg-icons'
/*
1 stella: sotto il 10% di apertura
2 stelle: 11%->30%
3 stelle 31%->50%
4 stelle: 51%->75%
5 stelle:  76%->100%
*/

const style = {
  fontSize: '12px',
  marginRight: '4px',
}

function EmptyStar() {
  return (
    <FontAwesomeIcon
      icon={faStarSolid}
      color="rgba(138,138,142,0.43)"
      style={style}
    />
  )
}

function FullStar() {
  return <FontAwesomeIcon icon={faStarSolid} color="#F4D931" style={style} />
}

function StarsWithoutOverlay({rate}) {
  return (
    <div className="d-flex flex-row">
      <FullStar />
      {rate > 10 ? <FullStar /> : <EmptyStar />}
      {rate > 30 ? <FullStar /> : <EmptyStar />}
      {rate > 50 ? <FullStar /> : <EmptyStar />}
      {rate > 75 ? <FullStar /> : <EmptyStar />}
    </div>
  )
}

function StarsWithOverlay({rate, placement = 'top'}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={`stars-tooltip-${placement}-${rate}`}>
          Successo <strong>{rate}%</strong>
        </Tooltip>
      }>
      <div>
        <StarsWithoutOverlay rate={rate} />
      </div>
    </OverlayTrigger>
  )
}

function Stars({rate, enableTooltip = false, placement}) {
  if (enableTooltip) {
    return <StarsWithOverlay rate={rate} placement={placement} />
  }
  return <StarsWithoutOverlay rate={rate} />
}

export default memo(Stars)
