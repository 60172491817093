import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
// import NavBar from '../Navbar'
import ExpoMap from './ExpoMap'
// import Footer from '../Footer'
import ExpoDrawer from './ExpoDrawer'
import {useAuthenticator} from '@aws-amplify/ui-react'
import {Auth} from 'aws-amplify'
import useAxios from 'axios-hooks'
import {
  apiBaseUrl,
  normalApiTimeout,
  ExpoIgnoredUsersId,
  mapFitBoundsSecondsInterval,
  expoMapPollingSeconds,
  mapDefaultCenter,
} from '../../Config'
import useInterval from '../../Hooks/Interval'
import {isAfter, startOfDay, addSeconds} from 'date-fns'
import {getTrackingStatus, TrackingStates} from '../../utilities/tracking-utils'

function createData({
  userId,
  name,
  surname,
  workHours,
  color,
  lastStates,
  lastPosition,
}) {
  const trackingState = getTrackingStatus(lastStates?.[0], lastPosition)
  return {
    userId,
    isTracking: trackingState === TrackingStates.TRACKING,
    name,
    surname,
    workHours,
    color,
    latitude: lastPosition?.latitude ?? null,
    longitude: lastPosition?.longitude ?? null,
    lastTimestamp: lastPosition?.serverTimestamp ?? null,
    isInvalidPosition: lastPosition?.isInvalidPosition ?? false,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    // flexGrow: 1,
    flex: 1,
    // backgroundColor: 'red',
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
    left: 240,
    right: 0,
    top: 0,
    bottom: 0,
  },
}))

function Expo() {
  const classes = useStyles()
  let operators = useMemo(() => [], [])
  let todayOperators = []
  const [canZoom, setCanZoom] = useState(true)

  const mapRef = useRef(null)
  const setMapInstance = (map) => {
    mapRef.current = map
  }
  const [lastUpdate, setLastUpdate] = useState(null)
  const [token, setToken] = useState()
  const {signOut} = useAuthenticator((context) => [context.user])
  // const {user, signOut} = useAuthenticator((context) => [context.user])
  // const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  const [
    {data: responseData, loading: fetching, error: requestError},
    executeRequest,
  ] = useAxios(
    {
      url: `${apiBaseUrl}/positions?state=true`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    },
    {manual: true}
  )

  const getCurrentTokenAndExecute = useCallback(async () => {
    try {
      const user = await Auth.currentSession()
      const currentToken = user.getAccessToken().getJwtToken()
      setToken(currentToken)
      executeRequest()
    } catch (error) {
      console.error('Impossible to retrieve current session')
      console.error(error)
      setToken(null)
    }
  }, [executeRequest])

  if (!fetching && !requestError && responseData) {
    // console.log('responseDate', responseData)
    operators = responseData
      .filter((operator) => !ExpoIgnoredUsersId.includes(operator.userId))
      .map((operator) =>
        createData({
          userId: operator.userId,
          name: operator.name,
          surname: operator.surname,
          workHours: '09:00-18:00',
          color: operator.color,
          lastStates: operator.lastStates,
          lastPosition: operator.lastPosition,
        })
      )
      .filter(
        ({latitude, longitude, color}) =>
          latitude != null && longitude != null && color
      )
    todayOperators = operators.filter(
      (operator) =>
        operator.lastTimestamp &&
        !operator.isInvalidPosition &&
        isAfter(
          new Date(operator.lastTimestamp),
          addSeconds(startOfDay(new Date()), -1)
        )
    )
    // console.log('operators', operators)
    // console.log('todayOperators', todayOperators)
  }

  // useEffect(() => {
  //   executeRequest()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (!!responseData && !requestError) {
      setLastUpdate(new Date())
    }
  }, [responseData, requestError, setLastUpdate])

  /**
   * This effect calculate and apply the maximum possible map zoom to include all the valid operators on map
   */
  useEffect(() => {
    if (
      canZoom &&
      todayOperators &&
      todayOperators.length > 0 &&
      mapRef.current
    ) {
      let bounds = new window.google.maps.LatLngBounds()
      todayOperators.map((item) => {
        bounds.extend(
          new window.google.maps.LatLng(item.latitude, item.longitude)
        )
        return item.userId
      })
      mapRef.current.fitBounds(bounds)
      setCanZoom(false)
    }
  }, [mapRef, todayOperators, canZoom])

  useEffect(() => {
    getCurrentTokenAndExecute()
  }, [getCurrentTokenAndExecute])

  /**
   * After mapFitBoundsSecondsInterval seconds, set the canZoom state to true
   * This update will trigger the effect to apply the map zoom to include all the operators pin on map
   */
  useInterval(() => {
    setCanZoom(true)
  }, mapFitBoundsSecondsInterval * 1000)

  useInterval(() => {
    if (!fetching) {
      // console.log()
      getCurrentTokenAndExecute()
    }
  }, expoMapPollingSeconds * 1000)

  let mapCenter = mapDefaultCenter
  if (mapRef.current) {
    const center = mapRef.current.getCenter()
    if (center) {
      mapCenter = {
        lat: center.lat(),
        lng: center.lng(),
      }
    }
  }

  return (
    <div className={classes.root}>
      {/* <header>
        <NavBar
          isAdmin={false}
          disableBottomMargin
          onLogout={signOut}
        />
      </header> */}
      <ExpoDrawer
        operators={operators}
        lastUpdate={lastUpdate}
        onLogout={signOut}
      />
      <main className={classes.main}>
        <div className={classes.absolute}>
          <ExpoMap
            operators={todayOperators}
            mapCenter={mapCenter}
            // setMapCenter={setMapCenter}
            setMapInstance={setMapInstance}
          />
        </div>
      </main>
      {/* <footer>
        <Footer disableTopMargin />
      </footer> */}
    </div>
  )
}

export default Expo
