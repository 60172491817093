import React from 'react'
// import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import useAxios from 'axios-hooks'
import {useParams, useNavigate} from 'react-router-dom'
import NavBar from '../Navbar'
import Footer from '../Footer'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import {useAuthenticator} from '@aws-amplify/ui-react'
import CustomerDetail from './CustomerDetail'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyItems: 'space-between',
  },
  main: {
    flex: 1,
  },
}))

// avoid browser cache
let now = Date.now()

function CustomerDetailPage(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const {customerHashKey, customerId} = useParams()
  const {user, signOut} = useAuthenticator((context) => [context.user])
  const token = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
  React.useEffect(() => {
    now = Date.now()
  }, [])
  const [{data: customerData, loading: loadingCustomer, error: errorCustomer}] =
    useAxios({
      url: `${apiBaseUrl}/customers/${customerId}?customerHashKey=${customerHashKey}&fakeTimestamp=${now}`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    })

  const [{data: checkins, loading: loadingCheckins, error: errorCheckins}] =
    useAxios({
      url: `${apiBaseUrl}/checkins?customerId=${customerId}&detailed=true&fakeTimestamp=${now}`,
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`},
      timeout: normalApiTimeout,
    })

  const fetching = loadingCustomer || loadingCheckins

  return (
    <div className={classes.root}>
      <header>
        <NavBar isAdmin loading={fetching} onLogout={signOut} />
      </header>
      <main className={classes.main}>
        <CustomerDetail
          customerData={customerData}
          checkins={checkins}
          loadingCustomer={loadingCustomer}
          onBack={() => navigate('/customers')}
          onEdit={() =>
            navigate(`/customers/${customerHashKey}/${customerId}/edit`)
          }
        />
      </main>
      <footer>
        <Footer />
      </footer>
      <ErrorSnackbar
        response={customerData}
        error={errorCustomer || errorCheckins}
        errorMessage={'Impossibile caricare tutti i dati del cliente'}
      />
    </div>
  )
}

CustomerDetailPage.propTypes = {}

export default CustomerDetailPage
