import React from 'react'
import PropTypes from 'prop-types'
import {Navigate, useLocation} from 'react-router-dom'
import {UserClass} from '../Config'

function RequireAdminAuth({children, isAuthenticated, userClass}) {
  // console.log('inside require admin auth')
  const location = useLocation()
  if (isAuthenticated && userClass === UserClass.ADMINISTRATOR) {
    // console.log('is admin')
    return children
  } else if (isAuthenticated) {
    // console.log('is base auth')
    return (
      <Navigate
        to={{
          pathname: '/expo',
        }}
      />
    )
  } else {
    // console.log('is not authenticated')
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{
          from: location,
        }}
      />
    )
  }
}

RequireAdminAuth.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  userClass: PropTypes.oneOf(Object.values(UserClass)).isRequired,
}

export default RequireAdminAuth
