import React, {useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomerDetailInfo from './CustomerDetailInfo'
import CustomerDetailCheckins from './CustomerDetailCheckinsPaging'
import BackButton from '../BackButton'
import {scrollToTop} from '../../utilities/layout-utils'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  container: {
    marginLeft: theme.spacing(4),
    maxWidth: theme.spacing(70),
    // backgroundColor: 'grey',
  },
  verticalSpacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}))

function CustomerDetail(props) {
  const classes = useStyles()
  const {checkins = [], customerData, loadingCustomer, onBack, onEdit} = props

  useLayoutEffect(scrollToTop, [])

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, classes.verticalSpacing)}>
        <BackButton onBack={onBack} />
      </div>
      <div className={classes.container}>
        <CustomerDetailInfo
          {...customerData}
          loadingCustomer={loadingCustomer}
          onEdit={onEdit}
        />
      </div>
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          Numero di check-in rilevati: {checkins.length}
        </Typography>
      </div>
      <CustomerDetailCheckins checkins={checkins} />
    </div>
  )
}

CustomerDetail.propTypes = {
  onEdit: PropTypes.func.isRequired,
  loadingCustomer: PropTypes.bool.isRequired,
  customerData: PropTypes.object,
  errorCustomer: PropTypes.object,
  checkins: PropTypes.array,
  // loadingCheckins: PropTypes.bool,
  errorCheckins: PropTypes.object,
}

export default CustomerDetail
