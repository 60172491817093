import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Formik} from 'formik'
import validationSchema from './CustomerEditValidation'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200,
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    marginBottom: theme.spacing(3),
  },
  button: {
    // margin: theme.spacing(1),
    minWidth: theme.spacing(34),
  },
  hourField: {
    minWidth: theme.spacing(7),
    marginRight: theme.spacing(2),
    // textAlign: 'right',
  },
  minuteField: {
    minWidth: theme.spacing(7),
    marginRight: theme.spacing(2),
    // textAlign: 'right',
  },
}))

function InnerForm(props) {
  const classes = useStyles()
  const {
    values: {name, address},
    errors,
    // touched,
    dirty,
    handleChange,
    // handleBlur,
    handleSubmit,
    isSubmitting,
    validateForm,
    /* and other goodies */
  } = props

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <form
      id="customerEditForm"
      className={classes.container}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}>
      <Grid container className={classes.gridContainer} spacing={3}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            id="name"
            name="name"
            label="Nome"
            className={classes.textField}
            margin="normal"
            fullWidth
            value={name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          {' '}
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <TextField
            id="address"
            name="address"
            label="Indirizzo"
            className={classes.textField}
            margin="normal"
            fullWidth
            multiline
            value={address}
            onChange={handleChange}
            error={!!errors.address}
            helperText={errors.address || ''}
          />
        </Grid>
      </Grid>
      <Button
        id="customerSubmit"
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={isSubmitting || !dirty}
        // disabled={isSubmitting}
        type="submit">
        Salva modifiche
      </Button>
    </form>
  )
}

function Form(props) {
  const {onSave, customerData} = props

  return (
    <Formik
      component={InnerForm}
      initialValues={customerData}
      validationSchema={validationSchema}
      onSubmit={onSave}
      enableReinitialize
    />
  )
}

export default Form
