import React from 'react'
import {Spinner} from 'react-bootstrap'

export default function Loader() {
  return (
    <div
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Spinner animation="grow" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  )
}
