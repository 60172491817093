import React from 'react'
import ResetPasswordRequestForm from './ResetPasswordRequestForm'
import AuthContainer from '../AuthContainer'
import ErrorSnackbar from '../CustomSnackbar/OnlyErrorSnackbar'
import LinearProgress from '@material-ui/core/LinearProgress'
import {apiBaseUrl, normalApiTimeout} from '../../Config'
import useAxios from 'axios-hooks'

export default function ResetPasswordRequest() {
  // console.log({apiBaseUrl})
  const [{data, loading, error}, executeRequest] = useAxios(
    {
      url: `${apiBaseUrl}/reset-password`,
      method: 'POST',
      timeout: normalApiTimeout,
    },
    {manual: true}
  )
  // console.log({data, loading, error})
  return (
    <div>
      {loading ? (
        <LinearProgress />
      ) : !!data && !error ? (
        <AuthContainer
          title="Richiesta inviata con successo"
          subtitle="Riceverai a breve un'email all'indirizzo specificato con le istruzioni per impostare la tua nuova password."
        />
      ) : (
        <AuthContainer
          title="Password dimenticata"
          subtitle="Indica la tua email per richiedere il reset della password">
          <ResetPasswordRequestForm
            onRequest={async (email) =>
              executeRequest({
                data: {email, type: 'request'},
              })
            }
            disabled={loading}
          />
        </AuthContainer>
      )}
      <ErrorSnackbar
        response={data}
        error={error}
        errorMessage="Errore durante la richiesta"
      />
    </div>
  )
}
