import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import UnreachableIcon from '@material-ui/icons/WifiOff'
import InvalidPositionIcon from '@material-ui/icons/LocationOffOutlined'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
// import red from '@material-ui/core/colors/red'
import {TrackingStates} from '../../utilities/tracking-utils'
import Ball from '../Ball'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minWidth: 650,
  },
  icon: {
    // margin: theme.spacing(1),
  },
  success: {
    color: green[500],
  },
  warning: {
    color: orange[500],
  },
  error: {
    color: theme.palette.error.main,
  },
}))

function OperatorsList({rows, onUserDetail}) {
  const classes = useStyles()

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell align="center">Attivo/Inattivo</TableCell>
          <TableCell align="left">Cognome</TableCell>
          <TableCell align="left">Nome</TableCell>
          <TableCell align="left">Tipo</TableCell>
          <TableCell align="left">GbPeople</TableCell>
          <TableCell align="right">Orario di lavoro</TableCell>
          <TableCell align="center">Colore</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            hover
            key={row.userId}
            onClick={() => onUserDetail(row.userId)}>
            <TableCell component="th" scope="row" align="center">
              {row.trackingState === TrackingStates.TRACKING ? (
                <DoneIcon className={clsx(classes.icon, classes.success)} />
              ) : row.trackingState === TrackingStates.UNREACHABLE ? (
                <UnreachableIcon
                  className={clsx(classes.icon, classes.warning)}
                />
              ) : row.trackingState === TrackingStates.INVALID_POSITION ? (
                <InvalidPositionIcon
                  className={clsx(classes.icon, classes.warning)}
                />
              ) : (
                <ClearIcon className={clsx(classes.icon, classes.error)} />
              )}
            </TableCell>
            <TableCell align="left">{row.surname}</TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">{row.userClass}</TableCell>
            <TableCell align="left">
              {row.isGbPeopleExposable ? 'Sì' : ''}
            </TableCell>
            <TableCell align="right">{row.workHours}</TableCell>
            <TableCell align="center">
              <Ball color={row.color} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

OperatorsList.propTypes = {
  onUserDetail: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      trackingState: PropTypes.string.isRequired,
      name: PropTypes.string,
      surname: PropTypes.string,
      workHours: PropTypes.string,
      color: PropTypes.string.isRequired,
      userClass: PropTypes.string,
    })
  ).isRequired,
}

export default OperatorsList
