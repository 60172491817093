import {isBefore, subMinutes} from 'date-fns'
import {unreachableMinutesLimit} from '../Config'

export const TrackingStates = Object.freeze({
  TRACKING: 'tracking',
  NOT_TRACKING: 'not_tracking',
  UNREACHABLE: 'unreachable',
  INVALID_POSITION: 'invalid_position',
})

function computeStatus(lastStartStopState, lastPosition) {
  const dateLimit = subMinutes(new Date(), unreachableMinutesLimit)
  const isStartBeforeLimit = isBefore(
    new Date(lastStartStopState.serverTimestamp),
    dateLimit
  )
  if (!lastPosition) {
    return isStartBeforeLimit
      ? TrackingStates.UNREACHABLE
      : TrackingStates.TRACKING
  }

  const isPositionBeforeLimit = isBefore(
    new Date(lastPosition.serverTimestamp),
    dateLimit
  )
  return isStartBeforeLimit && isPositionBeforeLimit
    ? TrackingStates.UNREACHABLE
    : lastPosition.isInvalid
    ? TrackingStates.INVALID_POSITION
    : TrackingStates.TRACKING
}

export const getTrackingStatus = (lastStartStopState = {}, lastPosition) => {
  switch (lastStartStopState?.state) {
    case 'start':
      return computeStatus(lastStartStopState, lastPosition)
    default:
      return TrackingStates.NOT_TRACKING
  }
}
