import React from 'react'
import {Card, Accordion, Button, Form} from 'react-bootstrap'
import DatePicker, {registerLocale} from 'react-datepicker'
import it from 'date-fns/locale/it'
import {
  addDays,
  isToday,
  startOfDay,
  setHours,
  addHours,
  setMinutes,
  roundToNearestMinutes,
} from 'date-fns'
registerLocale('it', it)

export default function StepScheduler(props) {
  const {
    setActiveKey,
    startDate,
    setStartDate,
    isImmediate,
    onChangeImmediate,
  } = props
  const handleSubmit = (evt) => {
    evt.preventDefault()
    setActiveKey('recap')
  }
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="scheduler"
          onClick={() => setActiveKey('scheduler')}>
          <span className="stepActive mr-3">3</span>Pianificazione
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="scheduler">
        <Card.Body className="ml-4 flex-column d-flex bd-highlight mb-3">
          <Form
            onSubmit={handleSubmit}
            className="flex-column d-flex justify-content-between"
            style={{flex: 1}}>
            <div className="mb-auto p-2 bd-highlight">
              <Form.Label className="formLabel clearfix col-xs-10">
                Data e ora dell'invio
              </Form.Label>
              <Form.Group
                onChange={(evt) => onChangeImmediate(evt.target.value)}>
                <Form.Check
                  type="radio"
                  id="radio-button-immediate"
                  label="Invia immediatamente"
                  name="radioButtonImmediate"
                  value="true"
                  defaultChecked={isImmediate}
                />

                <Form.Check
                  type="radio"
                  id="radio-button-scheduled"
                  name="radioButtonImmediate"
                  label="Imposta data e ora dell'invio"
                  value="false"
                  defaultChecked={!isImmediate}
                />
                {!isImmediate && (
                  <DatePicker
                    inline
                    disabled={isImmediate}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    locale="it"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="orario"
                    dateFormat="d MMMM yyyy 'alle' HH:mm"
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 31)}
                    minTime={
                      isToday(new Date(startDate))
                        ? roundToNearestMinutes(addHours(new Date(), 0), {
                            nearestTo: 15,
                          })
                        : startOfDay(new Date())
                    }
                    maxTime={setHours(setMinutes(new Date(), 45), 23)}
                  />
                )}
              </Form.Group>
            </div>
            <div className="d-flex mb-0 justify-content-between ">
              <Button
                onClick={() => setActiveKey('content')}
                className="btn-primary"
                size="lg">
                indietro
              </Button>
              <Button type="submit" className="btn-primary" size="lg">
                avanti
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}
