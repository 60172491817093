import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import NewCampaign from '../NewCampaign'
import {CampaignsProvider} from '../../Contexts/CampaignsContext'
import {TopicsProvider} from '../../Contexts/TopicsContext'

export default function ScrollDialog({open, setOpen}) {
  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      <CampaignsProvider>
        <TopicsProvider>
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogContent>
              <NewCampaign onSuccess={handleClose} />
            </DialogContent>
          </Dialog>
        </TopicsProvider>
      </CampaignsProvider>
    </div>
  )
}
